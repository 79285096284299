export const ddList = [
  

];
export const ddList1 = [


];
export const ddList2 = [
  

];
export const ddTitle = [
  { id: 0, prop: 'lb', label: '', width: '20', logo: true },
  { id: 1, prop: 'name', label: '作业对象', width: '110', text: true },
  { id: 2, prop: 'content', label: '作业面积', width: '120', text: true },
  { id: 3, prop: 'btn', label: 'distributionBtn', width: '50', btn: true, btnColor: 'yellow' }
];
export const ddTitle1 = [
  { id: 0, prop: 'lb', label: '', width: '20', logo: true },
  { id: 1, prop: 'name', label: '作业对象', width: '130', text: true },
  { id: 2, prop: 'content', label: '作业机手', width: '100', text: true },
  { id: 3, prop: 'btn', label: 'EdiotBtn', width: '50', btn: true, btnColor: 'blue' }
];
export const ddTitle2 = [
  { id: 0, prop: 'lb', label: '', width: '20', logo: true },
  { id: 1, prop: 'name', label: '作业对象', width: '130', text: true },
  { id: 2, prop: 'content', label: '作业机手', width: '100', text: true },
  { id: 3, prop: 'btn', label: 'viewBtn', width: '50', btn: true, btnColor: 'yellow' }
];

