// 横向柱形图
const datas = [
  {
    name: '耕整作业',
    xm: '耕作机',
    value: '0',
    value1: '0',
    value3: '0',
    value2: '0亩/天',
  },
  {
    name: '插秧作业',
    xm: '插秧机',
    value: '0',
    value1: '0',
    value3: '0',
    value2: '0亩/天',
  },
  {
    name: '收割作业',
    xm: '收割机',
    value: '0',
    value1: '0',
    value3: '0',
    value2: '0亩/天',
  },
  {
    name: '植保作业',
    xm: '植保机',
    value: '0',
    value1: '0',
    value2: '0亩/天',
  },
];
const zData = datas.map(item => item.value2);
const xData = datas.map(item => {
  return `${ item.xm } ${ item.value1 }台${
    item.value == '100' ? '' : `(其中损坏${ item.value3 }台)`
  }`;
});
const yData = datas.map(item => item.name);
const pData = datas.map(item => item.value);
//const max = Math.ceil(Math.max(...yData) * 1.2);
const max = 100;
const maxData = [ max, max, max, max ]; //控制左边名字显示的数量
export const fwnjOption = {
  grid: {
    left: '20%',
    right: '20%',
    bottom: '10%',
    top: '10%',
    containLabel: false,
  },
  xAxis: [
    {
      show: false,
    },
    {
      show: false,
      splitLine: {
        show: false,
      },
    },
  ],
  yAxis: [
    {
      type: 'category',
      inverse: true,
      axisTick: 'none',
      axisLine: 'none',
      show: true,
      axisLabel: {
        padding: [ 0, 0, 15, 15 ],
        verticalAlign: 'bottom',
        align: 'left',
        textStyle: {
          color: '#fff',
          fontSize: 16,
          fontFamily: 'PingFang SC',
        },
        formatter: params => {
          return `${ params }`;
        },
      },
      data: xData,
    },
    {
      show: true,
      data: yData,
      position: 'left',
      axisLabel: {
        lineHeight: 0,
        verticalAlign: 'bottom',
        fontSize: 16,
        color: '#fff',
        formatter: '{value}',
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    {
      show: true,
      data: zData,
      offset: 5,
      position: 'right',
      axisLabel: {
        lineHeight: 0,
        verticalAlign: 'bottom',
        fontSize: 16,
        color: '#fff',
        formatter: '{value}',
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
  ],
  series: [
    {
      name: '进度',
      show: true,
      type: 'bar',
      barGap: '-100%',
      xAxisIndex: 1,
      barWidth: 20,
      itemStyle: {
        borderRadius: 4,
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#9CB7F4', // 0% 处的颜色
            },
            {
              offset: 1,
              color: '#007ADB', // 0% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
      label: {
        show: true,
        position: 'insideRight',
        formatter: '{c}%',
        offset: [ -10, 0 ],
        color: '#fff',
      },
      labelLine: {
        show: false,
      },
      z: 2,
      data: pData,
      animationDelay: 1000,
      animationDuration: 1000,
    },
    {
      name: '百分比',
      z: 1,
      show: true,
      type: 'bar',
      xAxisIndex: 1,
      barGap: '-100%',
      barWidth: 20,
      itemStyle: {
        borderRadius: 4,
        color: 'rgba(13, 55, 78, 1)',
      },
      data: maxData,
    },
  ],
};
// 饼图
let list = [
  { name: '3天内订单', value: 0 },
  { name: '4~7天内订单', value: 0 },
  { name: '7天以上订单', value: 0 },
];
let allCount = list.reduce((arr, cur) => arr + cur.value, 0);
var nameArray = list.map(item => {
  return item.name;
});
var color = [
  '#5EE485',
  '#56A5F1',
  '#F39032',
  '#65e5dd',
  '#7b2cff',
  '#fd5151',
  '#f071ff',
  '#85f67a',
  '#0baefd',
  '#fdcd0b',
  '#0bfdab',
  '#ff5353',
  '#ff72cb',
  '#8488ff',
];
var data = [];
for (var i = 0; i < list.length; i++) {
  data.push({
    value: list[i].value,
    name: list[i].name,
  });
}
export const rgzOption = {
  color: color,
  tooltip: {
    show: false,
    labelLine: {
      show: false,
    },
    showContent: false,
  },
  title: {
    text: allCount,
    subtext: '总订单',
    textAlign: 'center',
    left: '59%',
    top: 'center',
    textStyle: {
      color: '#ffffff',
      fontWeight: 'Medium',
      fontSize: '20',
    },
    subTextStyle: {
      color: '#85879E',
      fontSize: '16',
      fontWeight: 'Medium',
    },
  },
  legend: [
    {
      orient: 'vertical',
      formatter: params => {
        let value;
        list.forEach(v => {
          if (v.name == params) {
            value = v.value;
          }
        });
        return `{a|${ params }}  {b|${ value }}`;
      },
      left: 'left',
      top: 'center',
      align: 'left',
      itemGap: 25,
      textStyle: {
        color: '#fff',
        fontSize: '18',
        rich: {
          a: {
            fontSize: 18,
            color: '#fff',
          },
          b: {
            fontSize: 18,
            color: '#fff',
          },
        },
      },
      //图例标记的图形高度
      //   itemHeight: 10,
      //图例标记的图形宽度
      //   itemWidth: 10,
    },
  ],
  series: [
    {
      labelLine: false,
      name: '',
      type: 'pie',
      silent: false,
      clockwise: false,
      radius: [ '90%', '120%' ],
      width: '65%',
      height: '55%',
      emphasis: {
        scale: false,
      },
      center: [ '93%', '50%' ],
      top: 'center',
      data: data,
    },
  ],
};
