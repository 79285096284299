<template>
  <div>
  <div class="leftImg">
    <img v-if="uid===1" src="@/assets/njImgs/maskCount.png" alt="" />
    <img v-else src="@/assets/njImgs/maskCount_green.png" alt="" />

    <div class="tiBox">
    <div>农机状态：{{ stateParams.title }}</div>
    <div>{{ stateParams.count }}台</div>
    </div>
</div>
 </div>
</template>

<script>
export default {
  props: {
    stateParams: {
      type: Object,
      default: () => {},
    },
    uid:{
      type:Number,
      default: () => {},

    }
  },

};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.leftImg {
  width: companyW(500vw);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: companyW(500vw);
    height: companyH(500vh);
  }
  .tiBox {
    position: absolute;
    left: companyW(177vw);
    display: flex;
    flex-direction: column;
    div:nth-child(1) {
      font-size: companyH(22vh);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: companyH(47vh);
      margin-top: companyH(106vh);
    }
    div:nth-child(2) {
      font-size: companyH(70vh);
      font-family: DIN;
      font-weight: 400;
      color: #ffffff;
      text-shadow: 1px 1px 1px #33f8f8;
      -webkit-text-stroke: 1px #fff;
      -webkit-filter: drop-shadow(1px 1px 0px #33f8f8);
    }
  }
}
</style>
