<template>
  <div id="yzMap"></div>
</template>
<script>
import MapInit from '@/views/njall/components/TMap/MapInit.js';

export default {
  data () {
    return {
      map,
      zoom: 12
    };
  },
  mounted () {
    this.roadMap();
  },
  methods: {
    roadMap () {
      MapInit.init()
        .then(T => {
          this.T = T;
          const imageURL =
            'https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=c9cd7951db1ea5b311aae41f5c0d60e4';
          const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
          //   const lay2 = new T.TileLayer(imageURLT, { minZoom: 1, maxZoom: 18 });
          const config = { layers: [ lay ] };
          this.map = new T.Map('yzMap', config);
          this.map.addControl(window.TMAP_SATELLITE_MAP);
          this.map.centerAndZoom(new T.LngLat(120.16371, 30.4806), 16);
          this.map.addEventListener('click', this.MapClick);
          var points = [];
          points.push(new T.LngLat(120.16616, 30.4833));
          points.push(new T.LngLat(120.16545, 30.4842));
          points.push(new T.LngLat(120.16608, 30.48446));
          points.push(new T.LngLat(120.16668, 30.48363));
          var points1 = [];
          points1.push(new T.LngLat(120.16751, 30.48404));
          points1.push(new T.LngLat(120.16839, 30.4828));
          points1.push(new T.LngLat(120.17032, 30.48387));
          points1.push(new T.LngLat(120.16925, 30.48494));
          //创建面对象
          var polygon = new T.Polygon(points, {
            color: 'Transparent',
            weight: 3,
            opacity: 0.5,
            fillColor: '#571416FF',
            fillOpacity: 0.5
          });
          var polygon1 = new T.Polygon(points1, {
            color: 'Transparent',
            weight: 3,
            opacity: 0.5,
            fillColor: '#571416FF',
            fillOpacity: 0.5
          });
          this.map.addOverLay(polygon);
          this.map.addOverLay(polygon1);
        })
        .catch();
    },
    MapClick () {
      this.$listeners.btn(true, 'map');
    }
  }
  // init() {
  //   return new Promise((resolve, reject) => {
  //     // 如果已加载直接返回
  //     if (window.T) {
  //       console.log('地图脚本初始化成功...')
  //       resolve(window.T)
  //       reject('error')
  //     }
  //   })
  // }
};
</script>
<style scoped>


#yzMap {
  width: 100%;
  height: 100%;

}
</style>
