<template>
  <div>
    <el-dialog :visible.sync="chaMask" :show-close="false" @close="closeMask">
      <dv-border-box-1>
        <div class="ToolBox" v-if="uid < 3">
          <div class="close" @click="closeMask"></div>
          <slot></slot>
          <div class="NavList" v-if="jcType.length !== 0">
            <div
              class="flexJs"
              v-for="(item, index) in jcType"
              :key="item.id"
              @click="changeNav(item.car_type, index)"
            >
              <div
                :class="`NavOne currentNav ${jtShow == index ? 'active' : ''}`"
              >
                <div class="NavTitle">{{ item.car_type }}</div>
                <div class="NavTitle">
                  <span>{{ item.count }}</span>
                  <span class="NavSpan">台</span>
                </div>
              </div>
              <div class="jt" v-if="jtShow == index"></div>
            </div>
          </div>
          <div class="leftBox">
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="MachineList"
              style="width: 100%; height: 80%;overflow-y: scroll;"
            >
              <template v-for="v in mainTable">
                <el-table-column
                  :key="v.id"
                  align="center"
                  :prop="v.prop"
                  :label="v.label"
                  :min-width="v.width"
                  @cell-mouse-enter="ent"
                >
                  <template v-slot="{ row }" v-if="v.prop == 'picture'">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="row.picture"
                    ></el-image>
                  </template>
                  <template v-slot="{ row }" v-else-if="v.prop == 'hasBeidou'">
                    <span class="sfjr">{{ row.hasBeidou ? '是' : '否' }}</span>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="queryObj.pageSize"
              layout="total, prev, pager, next"
              :total="MachineList.total"
            >
            </el-pagination>
          </div>
          <!-- <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="queryObj.pageSize.pageSize"
                layout="total, prev, pager, next"
                :total="tableList.length"
              >
              </el-pagination> -->
        </div>
        <div class="finish" v-else-if="uid == 3 || uid == 4">
          <div class="close" @click="closeMask"></div>
          <div class="finishTitile" v-if="uid == 3">未完成订单</div>
          <div class="finishTitile" v-else-if="uid == 4">已完成订单</div>
          <div class="tableBox">
            <div class="tableTab">
              <div
                class="tableTabItem wxz"
                :class="tableActive == index ? 'xz' : ''"
                v-for="(item, index) in Tabletab"
                @click="tabChange(item.label, index)"
              >
                {{ item.label }}
              </div>
            </div>
            <div class="finishTable">
              <el-table
                stripe
                v-loading="tabLoading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="MachineList.records"
                style="width: 100%"
                height="36vh"
              >
                <template v-for="v in mainTable">
                  <!-- <el-table-column :key="v.id" align="center" :prop="v.prop" :label="v.label" :min-width="v.width"
                  @cell-mouse-enter="ent">
                  <template v-slot="{ row }" v-if="v.prop == 'orderEvaluate'">
                    <el-rate v-model="row.orderEvaluate" disabled="true" :colors="['#009ad6', '#009ad6', '#009ad6']"
                      disabled-void-color="#77787b"></el-rate>
                  </template>
                </el-table-column> -->
                  <!-- 作业轨迹按钮 -->
                  <el-table-column
                    v-if="v.btn"
                    :key="v.id"
                    align="center"
                    :prop="v.prop"
                    :label="v.label"
                    :sortable="v.isSort"
                    :min-width="v.width"
                    @cell-mouse-enter="ent"
                  >
                    <template slot-scope="scope">
                      <div class="toolStyle" @click="open(scope.row)">
                        查看路径
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 操作 operate -->
                  <el-table-column
                    v-else-if="v.operate"
                    :key="v.id"
                    align="center"
                    :prop="v.prop"
                    :label="v.label"
                    :sortable="v.isSort"
                    :min-width="v.width"
                    @cell-mouse-enter="ent"
                  >
                    <template slot-scope="scope">
                      <div class="fpdd" @click="opendia(scope.row)">
                        分配订单
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 育苗品种 -->
                  <el-table-column
                    v-else-if="v.seedingVariety"
                    :key="v.id"
                    align="center"
                    :prop="v.prop"
                    :label="v.label"
                    :sortable="v.isSort"
                    :min-width="v.width"
                    @cell-mouse-enter="ent"
                  >
                    <template slot-scope="scope">
                      <!-- <div class="fpdd">分配订单</div> -->
                      <div style="text-align: left">
                        <div class="ya" v-if="scope.row.seedingType == '芽苗'">
                          芽
                        </div>
                        <div
                          class="yang"
                          v-else-if="scope.row.seedingType == '秧苗'"
                        >
                          秧
                        </div>
                        <span>{{ scope.row.seedingVariety }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 交苗地址 -->
                  <el-table-column
                    v-else-if="v.address"
                    :key="v.id"
                    align="center"
                    :prop="v.prop"
                    :label="v.label"
                    :sortable="v.isSort"
                    :min-width="v.width"
                    @cell-mouse-enter="ent"
                  >
                    <template slot-scope="scope">
                      <!-- <div class="fpdd">分配订单</div> -->
                      <span
v-if="scope.row.deliveryType == '自取'"
                        >中心自取</span
                      >
                      <span
                        v-else-if="scope.row.deliveryType == '配送'"
                        class="ckdz"
                        >查看地址</span
                      >
                    </template>
                  </el-table-column>
                  <!-- 评价星星 -->

                  <el-table-column
                    v-else-if="v.pj"
                    :key="v.id"
                    align="center"
                    :prop="v.prop"
                    :label="v.label"
                    :sortable="v.isSort"
                    :min-width="v.width"
                    @cell-mouse-enter="ent"
                  >
                    <template slot-scope="scope">
                      <el-rate
                        v-model="scope.row[v.prop]"
                        :disabled="true"
                        :colors="['#009ad6', '#009ad6', '#009ad6']"
                        disabled-void-color="#77787b"
                      ></el-rate>
                    </template>
                  </el-table-column>
                  <!-- 必要的主体 -->
                  <el-table-column
                    v-else
                    :key="v.id"
                    align="center"
                    :prop="v.prop"
                    :label="v.label"
                    :sortable="v.isSort"
                    :min-width="v.width"
                    @cell-mouse-enter="ent"
                  ></el-table-column>
                </template>
              </el-table>
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="5"
                layout="total, prev, pager, next"
                :total="MachineList.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="toolBox_finish" v-else-if="uid > 4">
          <div class="close" @click="closeMask"></div>
          <div class="NavList" v-if="jcType.length !== 0">
            <div
              class="flexJs"
              v-for="(item, index) in jcType"
              :key="item.id"
              @click="changeNav(item.job_type, index)"
            >
              <div
                :class="`NavOne currentNav ${jtShow == index ? 'active' : ''}`"
              >
                <div class="NavTitle">{{ item.job_type }}</div>
                <div class="NavTitle">
                  <span>{{ item.jobNum }}</span>
                  <span class="NavSpan">亩</span>
                </div>
              </div>
              <div class="jt" v-if="jtShow == index"></div>
            </div>
          </div>
          <div class="leftBox ml">
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="MachineList.records"
              style="width: 100%; height: 100%"
            >
              <template v-for="v in mainTable">
                <el-table-column
                  :key="v.id"
                  align="center"
                  :prop="v.prop"
                  :label="v.label"
                  :min-width="v.width"
                  @cell-mouse-enter="ent"
                >
                  <template v-slot="{ row }" v-if="v.prop == 'orderEvaluate'">
                    <el-rate
                      v-model="row.orderEvaluate"
                      :disabled="true"
                      :colors="['#009ad6', '#009ad6', '#009ad6']"
                      disabled-void-color="#77787b"
                    ></el-rate>
                  </template>
                  <template v-else-if="v.operate" v-slot="{ row }">
                    <div class="fpdd" @click="opendia(row)">分配订单</div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="queryObj.pageSize"
              layout="total, prev, pager, next"
              :total="MachineList.total"
            >
            </el-pagination>
          </div>
          <div class="rightBox" v-if="uid > 2">
            <div class="mapTitle">作业路径</div>
            <div class="TrackMap">
              <Tmap :mapInfo="mapInfo"></Tmap>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
    <mapModel :mspMask="mspMask" :mapInfo="mapInfo" v-on="$listeners" @closeMapMask="closeMapMask">
    </mapModel>
    <NjddNjs
      :NjsVisible="NjsVisible"
      @closenjs="closenjs"
      :uid="3"
      :OrderInfo="OrderInfo"
      :currentLabel="currentLabel"
      :TanleContent="TanleContent1"
      :queryObj="queryObj"

    >
    </NjddNjs>
  </div>
</template>

<script>
// import MidChart from '@/views/njall/components/njzl/ToolChart.vue';
// import pagination from '@/components/pagination/pagination.vue';
import mapModel from './mapMode.vue';
import NjddNjs from '@/views/njall/components/njdd/njddnjs.vue';
import { getOrderIn } from '@/api/njApi/njzl.js';
import { getWorkNj } from '@/api/njApi/njdd.js';

import {
  tableList,
  MachineList2
} from '@/views/njall/mock/zyfwMock/zyfwToolMock.js';
import Tmap from '@/views/njall/components/TMap/map.vue';
export default {
  components: {
    // MidChart,
    Tmap,
    mapModel,
    NjddNjs
    // pagination
  },
  props: {
    MachineList: {
      type: Object,
      default: () => {}
    },
    changeMask: {
      type: Boolean,
      default: false
    },
    mainTable: {
      type: Array,
      default: () => []
    },
    NavList: {
      type: Array,
      default: () => []
    },
    TanleContent: {
      type: Array,
      default: () => []
    },
    jcType: {
      type: Array,
      default: () => []
    },
    uid: {
      type: Number
    }
  },
  data () {
    return {
      queryObj: {
        pageNum: 1,
        pageSize: 10
      },
      result:'',
      mapInfo:{},
      mspMask: false,
      jobType: '耕整作业',
      tableActive: 0,
      jtShow: 0,
      currentPage: 1,
      tableList,
      MachineList2,
      total: 400,
      tabLoading: true,
      currentInd: 1,
      Visible: false,
      OrderInfo: null,
      currentLabel: 'fenpeinj',
      NjsVisible:false,
      TanleContent1:[],
      // ...options,
      chaMask: false,
      rateColor: [ '#009ad6' ],
      Tabletab: [
        {
          label: '耕整作业'
        },
        {
          label: '机插作业'
        },
        {
          label: '机收作业'
        },
        {
          label: '植保作业'
        }
      ]
    };
  },
  watch: {
    changeMask: {
      handler (newv) {
        this.chaMask = newv;
      }
    }
  },

  methods: {
    open(row){
      this.mapInfo=row;
      this.mspMask=true;
    },
    closeMapMask () {
      this.mspMask = false;
      this.mapInfo=null;
    },
    getClass () {
      // if (this.uid === 1)
      return 'NavOne';
      // else return 'NavTwo';
    },
    handleSizeChange (val) {
      console.log(`每页 ${ val } 条`);
    },
    handleCurrentChange (val) {
      let obj = {};
      switch (this.uid) {
      case 1:
        obj = {
          carType: '',
          status: '空闲',
          pageNum: val,
          pageSize: 10
        };
        break;
      case 2:
        obj = {
          carType: '',
          status: '工作',
          pageNum: val,
          pageSize: 10
        };
        break;
      case 3:
        obj = {
          jobType: this.jobType,
          status: '未完成',
          pageNum: val,
          pageSize: 5
        };
        break;
      case 4:
        obj = {
          jobType: this.jobType,
          status: '已完成',
          pageNum: val,
          pageSize: 5
        };
        break;
      case 5:
        obj = {
          jobType: '',
          status: 'finish',
          pageNum: val,
          pageSize: 10
        };
        break;
      case 6:
        obj = {
          jobType: '',
          status: 'today',
          pageNum: val,
          pageSize: 10
        };
        break;
      case 7:
        obj = {
          jobType: '',
          status: 'rush',
          pageNum: val,
          pageSize: 10
        };
        break;
      default:
        '';
      }
      this.$listeners.getModeData(obj);
      // console.log(`当前页: ${ val }`);
    },
    closeMask () {
      this.jobType = '耕整作业';
      this.jtShow = 0;
      this.tableActive = 0;

      this.$emit('chaMask',false);
      this.$emit('getMask', false);
    },
    closeMask2 (item) {
      this.Visible = item;
    },
    tabChange (item, index) {
      this.tableActive = index;
      this.jobType = item;
      let obj = {
        jobType: item,
        status: '',
        pageNum: 1,
        pageSize: 5
      };
      if (this.uid == 3) {
        obj.status = '未完成';
      } else {
        obj.status = '已完成';
      }

      this.$listeners.getModeData(obj);
      this.tabLoading = true;
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    changeNav (id, index) {
      this.jtShow = index;
      let obj = {
        carType: id,
        status: '空闲',
        pageNum: 1,
        pageSize: 10
      };
      if (this.uid == 1) {
        obj.status = '空闲';
      } else if (this.uid == 2) {
        obj.status = '工作';
      } else if (this.uid == 5) {
        obj.status = 'finish';
        obj.jobType = id;
      } else if (this.uid == 6) {
        obj.status = 'today';
        obj.jobType = id;
      } else if (this.uid == 7) {
        obj.status = 'rush';
        obj.jobType = id;
      }
      if (id == '全部') {
        obj.carType = '';
      }
      this.$listeners.getModeData(obj);
      this.tabLoading = true;
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    ent () {},
    async opendia (row) {
      console.log(row);
      const { data } = await getOrderIn(row.id);
      console.log(data);
      this.OrderInfo = data;

      if (data.jobType === '耕整作业') {
        this.result = data.jobType;
      } else if (data.jobType === '机收作业') {
        this.result = data.jobType;
      } else {
        this.result = data.jobObj;
      }
      const res = await getWorkNj({
        objType: data.jobType,
        jobObj:  this.result,
      });
      this.TanleContent1=res.data.records;
      console.log(res);

      this.NjsVisible = true;

    },
    closenjs (item) {
      this.NjsVisible = item;


    },
  },

  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
    console.log(this.chaMask);
  },
  computed: {
    totalText () {
      return `共 ${ this.total } 条记录`;
    },
    changeNavClass () {
      return uid => {
        const className = this.map.get(uid);
        if (className) {
          return className;
        }
        return 'NavOne';
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}

::v-deep .el-dialog {
  width: companyW(1499vw);
  background: #000000;
  opacity: 0.9;
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.fpdd {
  width: companyW(100vw);
  height: companyH(35vh);
  line-height: companyH(35vh);
  font-size: companyW(14vw);
  background-color: #243948;
  border: 1px solid #1c65b8;
  margin: 0 auto;
}
.ToolBox {
  width: 95%;
  // height: companyH(605vh);
  margin: 0 auto;
  padding: companyH(88vh) 0;
  display: flex;

  .leftBox {
    width: 100%;
    height: companyH(600vh);
    overflow: hidden;
  }

  .NavList {
    display: flex;
    flex-direction: column;
    margin-right: companyW(10vw);

    .NavTwo {
      width: companyW(182vw);
      height: companyH(53vh);
      background-image: url(../../../../assets//njImgs//toolNav_green.png);
      // background-image: url(../../../../assets//njImgs//toolNav.png);
      background-size: cover;
      margin-bottom: companyH(10vh);

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #33f8f8;
      cursor: pointer;

      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #33f8f8;

        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
    }

    .NavOne {
      width: companyW(182vw);
      height: companyH(53vh);
      background-image: url('../../../../assets/njdd/wxz.png');
      // background-image: url(../../../../assets//njImgs//toolNav.png);
      background-size: 100% 100%;
      margin-bottom: companyH(10vh);
      opacity: 0.3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #fff;
      cursor: pointer;

      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;

        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #fff;
        }
      }
    }
  }
}

.NavOne:hover {
  background-image: url('../../../../assets/njdd/xf.png') !important;
  // opacity: 0.7 !important;
  // transition: all 0.5s;
}

.currentNav {
  opacity: 1 !important;
}

.active {
  background-image: url('../../../../assets/njdd/xz.png') !important;
}

::v-deep .el-table th {
  background: #1b4069;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}

::v-deep .cell {
  font-size: companyH(20vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}

::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}

::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}

.toolStyle {
  color: '#33f8f8' !important;
  font-size: companyH(14vh);
  text-decoration: underline;
  cursor: pointer;
}

.toolBox_finish {
  display: flex;

  // justify-content: space-between;
  .NavList {
    margin-left: companyW(70vw);
    margin-top: companyH(80vh);
    display: flex;
    flex-direction: column;
    margin-right: companyW(10vw);

    .NavTwo {
      width: companyW(182vw);
      height: companyH(53vh);
      background-image: url(../../../../assets//njImgs//toolNav_green.png);
      // background-image: url(../../../../assets//njImgs//toolNav.png);
      background-size: cover;
      margin-bottom: companyH(10vh);
      opacity: 0.3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #33f8f8;
      cursor: pointer;

      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #33f8f8;

        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
    }

    .NavOne {
      width: companyW(182vw);
      height: companyH(53vh);
      background-image: url('../../../../assets/njdd/wxz.png');
      // background-image: url(../../../../assets//njImgs//toolNav_green.png);
      // background-image: url(../../../../assets//njImgs//toolNav.png);

      background-size: 100% 100%;
      margin-bottom: companyH(10vh);
      opacity: 0.3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #fff;
      cursor: pointer;

      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;

        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #fff;
        }
      }
    }
  }

  .leftBox {
    box-sizing: border-box;
    margin-left: companyW(70vw);
    margin-top: companyH(80vh);
    width: 49%;
    height: companyH(500vh);
  }

  .rightBox {
    box-sizing: border-box;

    margin-right: companyW(110vw);
    margin-bottom: companyH(125vh);

    width: companyW(522vw);
    height: companyH(554vh);

    margin-top: companyH(55vh);
    margin-left: companyW(100vw);

    .mapTitle {
      color: #74cfff;
    }

    .TrackMap {
      margin-top: companyH(12vh);
      width: companyW(500vw);
      height: companyH(450vh);
      background-color: #fff;
    }
  }
}

.el-pagination {
  margin: 0 auto;
  box-sizing: border-box;
  margin-left: companyW(100vw);
  margin-top: companyW(30vh);
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  background: transparent !important;
  background-color: transparent !important;
  border: companyW(1vw) solid #00a8ff !important;
}

::v-deep .el-icon-arrow-left:before {
  color: #00a8ff;
}

::v-deep .el-pagination__total {
  color: #00a8ff;
}

::v-deep .el-pager li {
  background-color: transparent !important;
  // background-color: black;
  color: #00a8ff;
  border: #00a8ff solid 1px;
}

.paginationClass {
  width: 100%;
  height: 100%;
}

.ml {
  margin-left: 0 !important;
}

.close {
  width: companyW(40vw);
  height: companyW(40vw);
  background: url('../../../../assets/close.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: companyH(25vh);
  right: companyW(35vw);
}

.jt {
  width: companyW(15vw);
  height: companyH((24/37) * 53vh);

  background: url('../../../../assets/njdd/jt.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: companyH(10vh) 0 0 companyW(10vw);
}

.flexJs {
  display: flex;
  justify-content: space-between;
}

.sfjr {
  font-size: 12.4px;
}

.finish {
  width: 100%;
  height: companyH(616vh);

  .finishTitile {
    width: 100%;
    text-align: center;
    color: #7acdff;
    font-weight: bold;
    font-size: companyW(24vw);
    padding-top: companyH(41vh);
  }

  .tableBox {
    width: 100%;
    padding: companyW(30vw);

    .tableTab {
      height: companyH(30vh);
      display: flex;
      margin-bottom: companyH(10vh);
      .tableTabItem {
        height: companyH(30vh);
        width: companyW(90vw);
        margin-right: companyW(18vw);
        text-align: center;
        line-height: companyH(30vh);
        font-size: companyW(14vw);
        color: #009bed;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .tableTabItem:hover {
        background-image: url('../../../../assets/njdd/xf.png');
      }
      .wxz {
        background-image: url('../../../../assets/njdd/wxz.png');
      }

      .xz {
        background-image: url('../../../../assets/njdd/xz.png') !important;
      }
      .finishTable {
        // width: 100%;
      }
    }
  }
  .ya {
    text-align: center;
    margin-right: companyW(7vw);
    display: inline-block;
    width: companyW(35vw);
    height: companyH(30vh);
    border-radius: 5px;
    font-weight: bold;
    background-color: #4e6415;
  }
  .yang {
    text-align: center;
    margin-right: companyW(7vw);
    display: inline-block;
    width: companyW(35vw);
    height: companyH(30vh);
    border-radius: 5px;
    font-weight: bold;
    background-color: #1b826f;
  }
  .ckdz {
    color: #1c65b8;
    text-decoration: underline;
  }
}
</style>
