<template>
  <div>
    <el-dialog
      :visible="MapDiaVisible"
      :show-close="false"
      @close="closenjs"
      v-if="MapDiaVisible"
    >
      <dv-border-box-1>
        <div class="ToolBox">
          <div class="title">查看位置</div>
          <div class="close" @click="closenjs"></div>


          <Tmap :mapPoint="mapPoint" style="width: 100%;height: 470px;" ></Tmap>
        </div>

      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>
import Tmap from '@/views/njall/components/TMap/test.vue';
export default {
  components: {
    Tmap
  },
  props: {
    MapDiaVisible: {
      type: Boolean,
      default: false
    },
    mapPoint:{
      type:Array
    }

  },
  data () {
    return {


      tabLoading: false,
      currentId: 0,
      currentName: ''
    };
  },
  methods: {
    closenjs () {
      this.$emit('update:MapDiaVisible', false);
    },




  },

  created () {

  },
  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep .el-dialog {
  width: companyW(700vw);
  background: #000000;
  opacity: 1;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.ToolBox {
  width: 95%;
  height: companyH(605vh);
  margin: 0 auto;
  padding: companyH(88vh) 0;
  .title {
    position: absolute;
    top: companyH(20vh);
    left: 45%;
    font-size: companyW(24vw);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1dfcff;
  }

  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
}
</style>
