export const tableData = [
  {
    serialNum:1,
    png:'',
    jobObj: '爬山虎',
    operator: '雷锋',
    FieldArea: '62元/亩',
    JobPrice: '62亩',
    JobWhoHas: '3844元',
    JobTime: '2022/03/19',
    JobTrajectory: '查看轨迹',
    evaluate: 4,
  },{
    serialNum:2,
    png:'',
    jobObj: '爬山虎',
    operator: '雷锋',
    FieldArea: '2元/亩',
    JobPrice: '55亩',
    JobAll: '3844元',
    JobTime: '2022/03/19',
    JobTrajectory: '查看轨迹',
    evaluate: 2,
  },{
    serialNum:3,
    png:'',
    jobObj: '爬山虎',
    operator: '雷锋',
    FieldArea: '62元/亩',
    JobPrice: '62亩',
    JobAll: '3844元',
    JobTime: '2022/03/19',
    JobTrajectory: '查看轨迹',
    evaluate: 5,
  },
];

export const tableList = [
  {
    id:1,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 4,
  },{
    id:2,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 2,
  },{
    id:3,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },
  {
    id:4,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },
  {
    id:5,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },
  {
    id:6,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },

  {
    id:7,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },
  {
    id:8,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },
  {
    id:9,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },
  {
    id:10,
    jobObj: '爬山虎',
    user: '雷锋',
    workType: '耕',
    WorkPrice: '62/亩',
    price: '3844元',
    workTime: '2022/03/19',
    workArea:'60亩',
    address: 1,
  },


];
export const MachineList = [
  {
    id:1,
    png:'',
    model: '坤元机械',
    isBeidou:'有北斗',
    oldPrice:'10000',
    WhoHas: '雷锋',
    DeviceStatus: '空闲中',

  },
  {
    id:2,
    png:'',
    model: '坤元机械',
    oldPrice:'10000',
    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '空闲中',

  },
  {
    id:3,
    png:'',
    model: '坤元机械',
    oldPrice:'10000',
    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '空闲中',

  },
  {
    id:4,
    png:'',
    model: '坤元机械',
    oldPrice:'10000',
    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '空闲中',

  },
  {
    id:5,
    png:'',
    model: '爬山虎',
    oldPrice:'10000',
    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '空闲中',

  },
];

export const MachineList2 = [
  {
    id:1,
    png:'',
    model: '坤元机械',
    isBeidou:'有北斗',

    WhoHas: '雷锋',
    DeviceStatus: '正常',
    position:'查看位置'

  },
  {
    id:2,
    png:'',
    model: '坤元机械',

    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '正常',
    position:'查看位置'

  },
  {
    id:3,
    png:'',
    model: '坤元机械',

    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '正常',
    position:'查看位置'

  },
  {
    id:4,
    png:'',
    model: '坤元机械',

    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '正常',
    position:'查看位置'

  },
  {
    id:5,
    png:'',
    model: '爬山虎',

    isBeidou:'有北斗',
    WhoHas: '雷锋',
    DeviceStatus: '正常',
    position:'查看位置'

  },
];
