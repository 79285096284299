let img1 = require('@/assets/njImgs/toolBg.png');

import * as echarts from 'echarts';
var list = [
  '耕整机 0%',
  '插秧机 0%',
  '收割机 0%',
  '植保机 0%',
  '育秧机 0%',
  '烘干机 0%',
  '加工机械 0%',
  '其他机型 0%',
];
let nameArr = list.map(v => {
  return `${ v.name }\t\t\t${ v.prosda }%`;
});
const toolnj = {
  title: {
    text: '农机总量',
    x: 'center',
    textStyle: {
      fontSize: '36',
      color: '#00A8FF',
    },
  },

  legend: {

    orient: 'horizontal', //水平排列显示
    align: 'left', //图例在左，文字在右
    top: '80%',// 这个是重要信息，因为是横着排列，如果这个值过大，没有空间换行
    width: '460',// 这个定义图例的总宽度，
    textStyle: {
      color: '#fff',
      // 这个宽度和上一级的宽度，有关系，这个宽度略小于上一级的一半，则第三个就会换行
      width: 106,
      overflow: 'break',
      fontSize:20
    },





    itemGap: 20,
    icon: 'circle',

    formatter: params => {
      let newv = '';
      let njPieData = toolnj.series[0].data;
      njPieData.forEach(v => {
        if (v.name == params) {
          let bfb = Math.floor((v.value / v.count) * 100);
          newv = `${ params } ${ bfb }%`;
        }
      });
      return newv;
    },
  },
  graphic: {
    elements: [
      {
        type: 'image',
        z: 0,
        style: { image: img1, width: 308, height: 308 },
        left: 'center',
        top: 'center',
        position: [ 148, 140 ],
      },
    ],
  },
  series: [
    {
      type: 'pie',
      radius: [ '30%', '47%' ],
      itemStyle: {
        normal: {
          color: function (params) {
            return [
              '#5B8FF9 ',
              '#61DDAA',
              '#F6BD16',
              '#7262FD',
              '#78D3F8',
              '#9661BC',
              '#F6903D',
              '#125453',
              '#48382F',
            ][params.dataIndex];
          },
        },
      },

      data: [
        {
          name: '耕整机',
          value: '0',

        },
        {
          name: '插秧机',
          value: '0',

        },
        {
          name: '收割机',
          value: '0',

        },

      ],
    },
  ],
};
const tableData = [

];
const NavList = [
  { id: 0, label: '全部', value: '0', unit: '台' },
  { id: 1, label: '耕种机', value: '0', unit: '台' },
  { id: 2, label: '插秧机', value: '0', unit: '台' },
  { id: 3, label: '收割机', value: '0', unit: '台' },
  { id: 4, label: '植保机', value: '0', unit: '台' },
  { id: 5, label: '育秧机', value: '0', unit: '台' },
  { id: 6, label: '烘干机', value: '0', unit: '台' },
  { id: 7, label: '加工机械', value: '0', unit: '台' },
];
export { toolnj, tableData, NavList };
