<template>
  <div>
    <el-dialog
      :visible.sync="addVisible"
      :show-close="false"
      @close="closeMask"
      v-if="addVisible"
    >
      <dv-border-box-1>
        <div class="toolBox">
          <div class="close" @click="closeMask"></div>
          <div class="toptitle">新增委托</div>

          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div class="formItem">
              <div class="formBox">
                <div class="formTxtBox">作业业主</div>
                <div class="selectBox">
                  <el-form-item prop="value" label-width="0px">
                    <el-select
                      value-key="farmerId"
                      v-model="ruleForm.value"
                      filterable
                      placeholder="请选择"
                      :popper-append-to-body="false"
                      @change="handChange"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value.label"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- <div
                style="text-align: center; line-height: 70px; color: #005b5b"
              >
                |
              </div> -->
                <div class="buttonBox">
                  <!-- <button>新增农户</button> -->
                </div>
              </div>

              <div class="formBox">
                <div class="formTxtBox">作业类型</div>
                <div class="selectBox">
                  <el-form-item prop="modelvalue" label-width="0px">
                    <el-select
                      v-model="ruleForm.modelvalue"
                      filterable
                      placeholder="请选择"
                      :popper-append-to-body="false"
                      @change="priceChange"
                    >
                      <el-option
                        v-for="item in options1"
                        :key="item.value.label"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="formBox">
                <div class="formTxtBox">作业对象</div>
                <div class="selectBox">
                  <el-form-item prop="value111" label-width="0px">
                    <el-select
                      value-key="jobObj"
                      v-model="ruleForm.value111"
                      filterable
                      placeholder="请选择"
                      :popper-append-to-body="false"
                      @change="handChangejobObj"
                    >
                      <el-option
                        v-for="item in options3"
                        :key="item.jobObj"
                        :label="item.jobObj"
                        :value="item.jobObj"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- <div
                style="text-align: center; line-height: 70px; color: #005b5b"
              >
                |
              </div> -->
                <div class="buttonBox">
                  <!-- <button>新增农户</button> -->
                </div>
              </div>
              <div class="formBox">
                <div class="formTxtBox">作业田块</div>
                <div class="selectBox">
                  <el-form-item prop="fieldValue" label-width="0px">
                    <el-select
                      value-key="jobFieldId"
                      v-model="ruleForm.fieldValue"
                      filterable
                      placeholder="请选择"
                      :popper-append-to-body="false"
                      @change="handChange2"
                    >
                      <el-option
                        v-for="item in options2"
                        :key="item.value.label"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  style="text-align: center; line-height: 70px; color: #005b5b"
                >
                  |
                </div>

                <div style="font-size: 16px; margin: 0 10px; line-height: 70px">
                  作业面积
                </div>

                <div
                  style=" margin-right: 10px; text-align: center; line-height: 70px; color: #005b5b"
                >
                  |
                </div>
                <div
                  class="inputBox"
                  style="line-height: 70px; margin-right: 10px"
                >
                  <el-form-item prop="inputValue" label-width="0px">
                    <el-input v-model="ruleForm.inputValue" style="margin-top: 10px;"></el-input>
                  </el-form-item>
                </div>
                <div
                  style="margin-left: 10px; line-height: 70px; font-size: 18px"
                >
                  亩
                </div>
              </div>

              <div class="formBox">
                <div class="formTxtBox"></div>
              </div>

              <div class="formBox">
                <div class="formTxtBox">作业时间</div>
                <div class="dateBox">
                  <el-form-item prop="inputValue" label-width="0px">
                    <el-date-picker
                      v-model="ruleForm.timeValue1"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      @change="ddddd()"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
                <div class="dateBox" style="margin-left: 40px">
                  <el-form-item prop="inputValue" label-width="0px">
                    <el-select
                      value-key="start"
                      v-model="ruleForm.timeValue2"
                      filterable
                      placeholder="请选择"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in option3"
                        :key="item.value.start"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="submit">
              <button class="submitBox" @click="done">确认委托</button>
            </div>
          </el-form>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>
import {
  getfarmer,
  jobOrder,
  getFiled,
  getByType,
  getDetailPrice
} from '@/api/njApi/njdd.js';

export default {
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    options1: {
      type: Array
    }
  },
  components: {},
  created () {
    this.getList();
  },
  data () {
    return {
      rushOrder: null,

      ruleForm: {
        value: '',
        value111: '',
        modelvalue: '',
        inputValue: '',
        fieldValue: '',
        timeValue1: '',
        timeValue2: ''
      },
      rules: {
        value: [ { required: true, message: '请选择作业业主', trigger: 'blur' } ],
        value111: [
          { required: true, message: '请选择作业对象', trigger: 'blur' }
        ],
        modelvalue: [
          { required: true, message: '请选择作业类型', trigger: 'blur' }
        ],
        inputValue: [
          { required: true, message: '请输入田块面积', trigger: 'blur' }
        ],
        fieldValue: [
          { required: true, message: '请选择田块', trigger: 'blur' }
        ],
        timeValue1: [
          { required: true, message: '请选择作业时间', trigger: 'blur' }
        ],
        timeValue2: [
          { required: true, message: '请选择作业时间', trigger: 'blur' }
        ]
      },
      jobPrice: null,
      jobPriceAmount: null,
      options: [],
      options3: [],
      options2: [],
      option3: [
        {
          value: {
            start: '00:00:00',
            end: '11:59:59'
          },
          label: '上午'
        },
        {
          value: {
            start: '12:00:00',
            end: '23:59:59'
          },
          label: '下午'
        }
      ]
    };
  },
  methods: {
    async ByType (type) {
      const { data } = await getByType({ type });

      this.options3 = data;
    },
    async DetailPrice (type, job) {
      const { data } = await getDetailPrice({ type, job });
      this.jobPrice = data.jobPrice;
    },
    handChangejobObj () {
      this.DetailPrice(this.ruleForm.modelvalue, this.ruleForm.value111);
    },
    ddddd () {
      const today = new Date();
      const appointmentDate = new Date(this.timeValue1);

      // 检查日期是否大于今天
      const isDateGreaterThanToday = appointmentDate > today;

      // 根据日期判断 rush_order 的值
      const rushOrderValue = isDateGreaterThanToday ? 0 : 1;
      this.rushOrder = rushOrderValue;
    },
    async getList () {
      const { data } = await getfarmer({ search: '' });
      this.options = data.map(item => {
        return {
          value: {
            farmerName: item.name,
            farmerTel: item.telphone,
            farmerId: item.id
          },
          label: item.name
        };
      });
    },
    async handChange (farmerId) {
      const { data } = await getFiled({ farmerId: farmerId.farmerId });

      this.options2 = data.map(item => {
        return {
          value: {
            jobObj: item.plantType,
            jobNum: item.fieldArea,
            jobFieldId: item.id,
            jobFieldName: item.fieldName,
            jobLocation: item.address
          },
          label: item.fieldName
        };
      });
    },
    handChange2 (val) {
      this.inputValue = val.jobNum;
    },
    done () {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          const data = {
            jobOrder: {
              jobType: this.ruleForm.modelvalue,
              ...this.ruleForm.value,

              appointmentStart: `${ this.ruleForm.timeValue1 } ${ this.ruleForm.timeValue2.start }`,
              appointmentEnd: `${ this.ruleForm.timeValue1 } ${ this.ruleForm.timeValue2.end }`,
              isSelf: 0,
              rush_order: this.rushOrder
            },
            list: [
              {
                jobFieldId: this.ruleForm.fieldValue.jobFieldId,
                jobFieldName: this.ruleForm.fieldValue.jobFieldName,
                jobLocation: this.ruleForm.fieldValue.jobLocation,
                jobNum: Number(this.ruleForm.inputValue),
                jobObj: this.ruleForm.value111,
                jobPrice: this.jobPrice,
                jobPriceAmount: +this.putjobPriceAmount
              }
            ]
          };
          let customConfig = {
            headers: {
              'Content-Type': 'application/json'
            }
          };
          jobOrder(data, customConfig)
            .then(res => {
              this.$message(res.msg);
            })
            .catch(err => {
              console.log(err);
            });

          this.$emit('getData');
          this.$emit('parent');

          this.closeMask();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    priceChange (val) {
      this.ByType(val);
    },

    closeMask () {
      this.$emit('closeMask', false);
      this.ruleForm.value = '';
      this.ruleForm.value111 = '';
      this.ruleForm.modelvalue = '';
      this.ruleForm.fieldValue = '';
      this.ruleForm.inputValue = '';
      this.ruleForm.timeValue1 = '';
      this.ruleForm.timeValue2 = '';
    },

    getNowTime () {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, '0');
      date = date.toString().padStart(2, '0');
      var defaultDate = `${ year }-${ month }-${ date }`;
      return defaultDate;
    }
  },
  computed: {
    putjobPriceAmount () {
      return `${ this.inputValue * this.jobPrice }`;
    }
  }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
  color: #fff;
}

::v-deep .el-dialog {
  width: companyW(830vw);
  height: companyH(680vh);
  background: #000000;
  background: rgba($color: #000000, $alpha: 1);
  // opacity: 0.9;
  margin: 0 auto;
}

::v-deep .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  background-color: #999;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-select-dropdown {
  background-color: black;
  border: 1px solid #0081c3;
  color: #0081c3;
}

::v-deep .el-input--suffix .el-input__inner {
  width: companyW(250vw);
  height: companyH(30vh);
  border: 1px solid #005c8b;
  background-color: transparent;
}
::v-deep .el-input__inner {
  background-color: #1f2025;
}
::v-deep
  .el-date-el-date-editor
  .el-range-editor
  .el-input__inner
  .el-date-editor--daterange {
  width: companyW(250vw);
  height: companyH(30vh);
}
::v-deep .el-date-editor .el-range-input {
  background-color: transparent;
}
::v-deep [data-v-35c5c6cc] .el-input__inner {
  color: #fff;
}

::v-deep [data-v-35c5c6cc] .el-input--suffix .el-input__inner {
  color: #fff;
}
.toolBox {
  height: companyH(680vh);

  padding-left: companyW(80vw);
  padding-right: companyW(80vw);

  .toptitle {
    width: 100%;
    text-align: center;

    padding-top: companyH(30vh);
    margin-bottom: companyH(50vh);
  }
  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
  .formItem {
    width: companyW(640vw);
    height: companyH(350vh);
    margin-bottom: companyH(40vh);
    .formBox {
      width: 100%;
      height: companyH(70vh);
      border-bottom: #013131 1px solid;
      display: flex;
      justify-content: left;
      .formTxtBox {
        height: 100%;
        width: companyW(80vw);
        border-right: #013131 1px solid;
        line-height: companyH(70vh);
        font-size: companyW(16vw);
        text-align: center;
      }
      .selectBox {
        margin: companyH(20vh) companyW(10vw);
      }
      .buttonBox {
        margin-left: companyW(20vw);
        line-height: companyH(70vh);

        button {
          width: companyW(110vw);
          height: companyH(30vh);
          font-size: companyW(16vw);
          background-color: #1f2227;
          color: #0081c3;
          font-weight: 700;
          border: 1px solid #0081c3;
        }
      }
      .dateBox {
        line-height: companyH(70vh);
        margin-left: companyW(20vw);
        .el-input__inner {
          border: #0081c3 1px solid;
        }
      }
      .inputBox {
        box-sizing: border-box;
        width: companyW(80vw);
        height: companyH(30vh);
      }
    }
  }
  .submit {
    margin-top: companyH(120vh);
    text-align: center;

    .submitBox {
      width: companyW(200vw);
      height: companyH(40vh);
      font-size: companyW(25vw);
      border: 1px solid #0081c3;
      color: #fff;
      background-color: transparent;
    }
  }
}
</style>
