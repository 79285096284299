<template>
  <div>
    <el-dialog
      :visible.sync="chaMask"
      :show-close="false"
      @close="closenjs"
      v-if="chaMask"
    >
      <dv-border-box-1>
        <div class="toolbox">
          <div class="close" @click="closenjs"></div>

          <div class="titleBox">
            <div class="leftTitle">订单信息</div>
            <div class="rightTitle" v-if="currentLabel === 'distributionBtn'">
              分配订单
            </div>
            <div class="rightTitle" v-else-if="currentLabel === 'EdiotBtn'">
              修改订单
            </div>
            <div class="rightTitle" v-else-if="currentLabel === 'fenpeinj'">
              可分配农机
            </div>
            <div class="rightTitle" v-else>作业信息</div>
          </div>

          <div class="bodyBox">
            <div class="bodyLeft">
              <div class="txtClass">
                <div class="z-img">作业对象</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.farmerName }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">联系方式</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.farmerTel }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">作业类型</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.jobType }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">作业面积</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.jobNum }}亩</div>
              </div>

              <div class="txtclass2">
                <div class="z-img">作业位置</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">
                  {{ OrderInfo?.jobLocation || '暂无数据' }}
                </div>
              </div>
              <div class="txtClass">
                <div class="z-img">下单时间</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.orderTime }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">作业时间</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">
                  {{ OrderInfo?.appointmentStart }}~{{
                    OrderInfo?.appointmentEnd
                  }}
                </div>
              </div>
              <div class="Eidot">
                <!-- <button class="submit_Eidot" v-if="currentLabel !== 'viewBtn'">
                  编辑
                </button> -->
              </div>
            </div>

            <div class="segmentation">
              <img src="../../../../assets/njImgs/segmentation.png" alt="" />
            </div>

            <div
              class="bodyRight"
              style="transform: translateY(-7%)"
              v-if="uid === 2"
            >
              <el-table
                stripe
                v-loading="tabLoading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="TanleContent"
                style="width: 100%"
                height="500"
              >
                <el-table-column type="index" label="序号" width="60">
                </el-table-column>
                <el-table-column prop="name" label="操作手" align="center">
                </el-table-column>
                <el-table-column
                  prop="telphone"
                  label="联系方式"
                  width="140"
                  align="center"
                >
                </el-table-column>
                <el-table-column label="证照信息" align="center" width="100">
                  <template v-slot="{ row }">
                    <div @click="openDiaImg(row)">查看</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="completedArea"
                  label="已作业面积"
                  width="120"
                  align="center"
                >
                </el-table-column>

                <el-table-column
                  prop="completedCount"
                  label="作业次数"
                  align="center"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="evaluate"
                  label="综合评价"
                  width="140"
                  align="center"
                >
                  <template v-slot="{ row }">
                    <el-rate
                      v-model="row.evaluate"
                      :disabled="true"
                      :colors="['#009ad6', '#009ad6', '#009ad6']"
                      disabled-void-color="#77787b"
                    ></el-rate>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template v-slot="{ row }">
                    <div style="font-size: 16px" @click="emitInfo(row, 1)">
                      分配
                    </div>
                  </template>
                </el-table-column>
                <div slot="empty" style="font-size: 20px; color: #fff">
                  暂无农机手
                </div>
              </el-table>
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryObj.pageNum"
                :page-size="queryObj.pageSize"
                layout="total,prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
            <div
              class="bodyRight"
              style="transform: translateY(-7%)"
              v-else-if="currentLabel === 'fenpeinj'"
            >
              <el-table
                stripe
                v-loading="tabLoading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="TanleContent"
                style="width: 100%"
                height="500"
              >
                <el-table-column type="index" label="序号" width="60">
                </el-table-column>
                <el-table-column prop="picture" label="图片" align="center">
                  <template v-slot="{ row }">
                    <el-image
                      style="width: 80px; height: 80px"
                      :src="row.picture"
                      fit="contain"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="model"
                  label="型号"
                  width="160 "
                  align="center"
                >
                </el-table-column>

                <el-table-column
                  prop="carOwner"
                  label="作业机手"
                  width="120 "
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="telphone"
                  label="联系方式"
                  align="center"
                  width="160 "
                >
                </el-table-column>

                <el-table-column label="当前位置" width="120 " align="center">
                  <template v-slot="{ row }">
                    <div style="width: 80px; height: 80px">
                      <!-- 天地图显示机手当前的位置 -->
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120">
                  <template v-slot="{ row }">
                    <div style="font-size: 16px" @click="emitInfo(row, 3)">
                      分配就近机手
                    </div>
                  </template>
                </el-table-column>
                <div slot="empty" style="font-size: 20px; color: #fff">
                  暂无农机
                </div>
              </el-table>
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryObj.pageNum"
                :page-size="queryObj.pageSize"
                layout="total,prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
            <div class="bodyRight" style="transform: translateY(-7%)" v-else>
              <el-table
                stripe
                v-loading="tabLoading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="TanleContent"
                style="width: 100%"
                height="500"
                ><el-table-column type="index" label="序号" width="60">
                </el-table-column>
                <el-table-column prop="picture" label="图片" align="center">
                  <template v-slot="{ row }">
                    <el-image
                      style="width: 80px; height: 80px"
                      :src="row.picture"
                      fit="contain"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="model"
                  label="型号"
                  width="120 "
                  align="center"
                >
                </el-table-column>
                <!-- <el-table-column prop="nameplate" label="铭牌" align="center">
                  <template v-slot="{ row }">
                    <el-image
                      style="width: 80px; height: 80px"
                      :src="row.nameplate"
                      fit="contain"
                    ></el-image>
                  </template>
                </el-table-column> -->

                <el-table-column
                  prop="hasBeidou"
                  label="是否加装北斗"
                  align="center"
                  width="130"
                >
                </el-table-column>
                <el-table-column
                  prop="carOwner"
                  label="所有人"
                  width="120 "
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="carPower"
                  label="动力(Kw)"
                  width="120 "
                  align="center"
                >
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template v-slot="{ row }">
                    <div style="font-size: 16px" @click="emitInfo(row, 2)">
                      分配
                    </div>
                  </template>
                </el-table-column>
                <div slot="empty" style="font-size: 20px; color: #fff">
                  暂无农机
                </div>
              </el-table>

              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryObj.pageNum"
                layout="total,prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
    <ImgDia :ImgDiaVisible.sync="ImgDiaVisible" :photoSrc="photoSrc"></ImgDia>
  </div>
</template>

<script>
import { postJobOrder } from '@/api/njApi/njzl.js';
import ImgDia from '@/views/njall/components/njdd/njddDiaImg.vue';

export default {
  props: {
    NjsVisible: {
      type: Boolean,
      default: false
    },
    uid: {
      type: Number
    },
    currentLabel: {
      type: String
    },
    OrderInfo: {
      type: Object,
      default: () => {}
    },
    TanleContent: {
      type: Array,
      default: () => []
    },
    queryObj: {
      type: Object
    },
    total:{
      type:Number
    }
  },
  components: {
    ImgDia
  },
  data () {
    return {
      tabLoading: true,

      photoSrc: '',
      ImgDiaVisible: false,
      chaMask: false,
    };
  },
  watch: {
    NjsVisible: {
      handler (newv, oldv) {
        this.chaMask = newv;
      }
    }
  },
  created () {},
  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);

  },
  methods: {
    async emitInfo (row, type) {
      if (!row) return;
      if (type === 1) {
        const { name, telphone, id } = row;

        this.$emit('emitInfo', { name, telphone, id });
      } else if (type === 2) {

        const { carBrand, model, id } = row;

        this.$emit('emitInfo2', { carBrand, model, id });
      } else {

        let res = {
          id: this.OrderInfo.id,
          jobPrice: this.OrderInfo.jobPrice,
          jobPriceAmount: this.OrderInfo.jobPriceAmount,
          jobTractorName: row.carOwner,
          jobTractorId: '',
          jobTractorTel: row.telphone,
          jobMachineryId: row.id,
          jobStartTime: this.OrderInfo.appointmentStart,
          jobFinishTime: this.OrderInfo.appointmentEnd,

          rushOrder: true
        };

        const data = await postJobOrder({ ...res });
        this.$message.success(data.msg);
      }
      this.closenjs();
    },
    openDiaImg (row) {

      this.photoSrc = row.photo;
      this.ImgDiaVisible = true;
    },
    closenjs () {
      this.$emit('closenjs', false);

      this.$emit('update:uid', 2);
      this.$emit('update:total', null);

    },

    handleSizeChange (val) {
      console.log(`每页 ${ val } 条`);
      this.$emit('handleSizeChange', val);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${ val }`);
      this.$emit('handleCurrentChange', val);
    }
  }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(16vw);
}

::v-deep .el-dialog {
  width: companyW(1548vw);
  height: companyH(630vh);
  background: #000000;
  background: rgba($color: #000000, $alpha: 0.9);
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table th {
  background: #1b4069;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}

::v-deep .cell {
  font-size: companyH(14vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #71cdf9;
}

::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}

::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}

.el-pagination {
  margin: 0 auto;
  box-sizing: border-box;
  transform: translateX(35%);
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  background: transparent !important;
  background-color: transparent !important;
  border: companyW(1vw) solid #00a8ff !important;
}

::v-deep .el-icon-arrow-left:before {
  color: #00a8ff;
}

::v-deep .el-pagination__total {
  color: #00a8ff;
}
.toolbox {
  height: companyH(582vh);

  padding-top: companyH(30vh);
  margin-bottom: companyH(50vh);
  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
  .titleBox {
    text-align: center;
    margin-left: companyW(120vw);
    display: flex;

    .leftTitle {
      background-color: transparent;
      width: companyW(450vw);

      color: #5598bb;
      margin-left: companyW(-85vw);
    }
    .rightTitle {
      background-color: transparent;
      width: companyW(800vw);

      color: #5598bb;
      padding-left: companyW(-700vw);
    }
  }
  .bodyBox {
    margin-top: companyH(50vh);
    margin-left: companyW(60vw);
    height: 100%;
    display: flex;
    .bodyLeft {
      display: flex;
      flex-wrap: wrap;
      width: companyW(410vw);
      height: companyH(420vh);

      .txtclass2 {
        width: companyW(400vw);
        height: companyH(50vh);
        position: relative;

        .z-img {
          font-size: companyW(16vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #eff0f1;
        }
        img {
          position: absolute;
          top: companyH(10vh);
          left: companyW(-5vw);
          // z-index: -1;
        }
        .textCss {
          margin-top: companyH(17vh);
          font-size: companyW(18vw);
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .txtClass {
        width: companyW(200vw);
        height: companyH(60vh);
        position: relative;
        .z-img {
          font-size: companyW(16vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #eff0f1;
        }
        img {
          position: absolute;
          top: companyH(10vh);
          left: companyW(-5vw);
          // z-index: -1;
        }
        .textCss {
          margin-top: companyH(17vh);
          font-size: companyW(18vw);
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .Eidot {
        margin-left: companyW(40vw);
        margin-top: companyH(20vw);
        .submit_Eidot {
          width: companyW(201vw);
          height: companyH(41vh);
          background: rgba(55, 57, 66, 0.54);
          border: 1px solid #00a8ff;
          font-size: companyW(18vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #00a8ff;
          line-height: companyW(41vw);
        }
      }
    }
    .bodyRight {
      width: companyW(830vw);
      height: 100%;
      .distribution {
        width: companyW(240vw);
        height: companyH(160vh);
        background: rgba(0, 121, 161, 0.6);
        border: 1px solid #00b9f7;
        // opacity: 0.4;
        .distributionTitle {
          text-align: center;
          width: companyW(238vw);
          height: companyH(40vh);
          font-size: companyW(18vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: companyH(40vh);
          background: #013656;
        }
        .distributionBody {
          text-align: center;
          line-height: companyW(160vh);
          button {
            width: companyW(81vw);
            height: companyH(25vh);
            background: rgba(55, 57, 66, 0.54);
            border: 1px solid #00a8ff;
            font-size: companyW(12vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #00a8ff;
            line-height: companyH(25vh);
          }
          .txtDiv {
            font-size: companyW(16vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          .buttonPrice {
            width: companyW(81vw);
            height: companyH(25vh);
            background: rgba(55, 57, 66, 0.54);
            border: 1px solid #00a8ff;
            font-size: companyW(12vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #00a8ff;
            transform: translateY(-200%);
          }
        }
      }
      .leftInfo {
        width: companyW(300vw);
        height: companyH(400vh);
        .txtclass3 {
          width: companyW(400vw);
          height: companyH(60vh);
          position: relative;

          .z-img {
            font-size: companyW(18vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #eff0f1;
          }
          img {
            position: absolute;
            top: companyH(10vh);
            left: companyW(-5vw);
            // z-index: -1;
          }
          .textCss {
            margin-top: companyH(17vh);
            font-size: companyW(20vw);
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .rightMap {
        transform: translateX(5%);
        width: companyW(400vw);
        height: companyH(400vh);
      }
    }
  }
}
</style>
