<template>
  <div class="njServe">
    <Title
      class="njTitle"
      njTitle="NjImg"
      typeOf="njImgs"
      :title="address + '数字农机'"
    ></Title>

    <div class="ContentBox">
      <div class="ContentLeft">
        <MkContent titleSrcName="njTitle" titleName="作业能力">
          <LeftCont :propOptions="fwnjOption"></LeftCont>
        </MkContent>

        <MkContent
          titleSrcName="njTitle"
          titleName="需求情况"
          style="margin-top: 10px"
        >
          <leftCont :propOptions="rgzOption"></leftCont>
        </MkContent>

        <MkContent
          titleSrcName="njTitle"
          titleName="对比上年情况"
          style="margin-top: 0px"
        >
          <div class="dbsnClass">
            <div class="dbsnTop">
              <div
                v-for="v in njddBtnData"
                :key="v.id"
                @click="currentNameChange(v.title)"
                :style="currentName == v.title ? '' : 'color:#fff;border:0'"
              >
                {{ v.title }}
              </div>
            </div>
            <div style="display: flex">
              <leftCont :propOptions="dbsnOption"></leftCont>

              <div
                class="lastYear-content-top"
                style="transform: translateX(-80%)"
              >
                <div class="content-top-item">
                  <div>
                    <div class="little-box"></div>
                    <span class="top-item-name">本年完成亩数</span>
                  </div>
                  <div class="top-item-number">{{ nowYear }}亩</div>
                </div>

                <div class="content-top-item">
                  <div>
                    <div class="little-box-red"></div>
                    <span class="top-item-name">去年完成亩数</span>
                  </div>
                  <div class="top-item-number-red">{{ lastYear }}亩</div>
                </div>
              </div>
            </div>
          </div>
        </MkContent>
      </div>

      <!-- 中间部分 -->

      <div class="midN">
        <div class="midN-div">
          <MidBox
            v-for="v in MidData"
            :key="v.id"
            :boxData="v"
            style="margin: 0 companyW(8vw)"
            @changeMaskd="changeMas"
          >
          </MidBox>
        </div>
        <div class="sjjk">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <span
class="centerTitle"
style="transform: translateY(-50%)"
              >当年累计订单量</span
            >
            <span
class="centerSj"
style="transform: translateY(-20%)"
              >{{ ordercount }}单</span
            >
            <span class="centerSj">{{ ordernum }}亩次</span>
          </div>
          <div class="midPosition">
            <el-select v-model="currentTime" @change="ljYearChange">
              <el-option
                v-for="item in TimeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="midN-div">
          <div v-for="(v, index) in bottomBtn" class="midBottom-One">
            <img
              :src="v.imgSrc"
              alt=""
              @click="showDetail(index + 5, 'left')"
            />
            <div class="leftMargin" style="font-size: 20px">
              <span :class="v.title == '紧急订单' ? 'order' : 'boxTitle'">{{
                v.title
              }}</span>
              <div>
                <span
                  :class="v.title == '紧急订单' ? 'orderValue' : 'normal'"
                  >{{ v.ds }}</span
                >
                <span style="font-size: 16px; color: #8b9eb3"> 单 </span>
                <span class="hang">|</span>
                <span
                  :class="v.title == '紧急订单' ? 'orderValue' : 'normal'"
                  >{{ v.value }}</span
                >
                <span style="font-size: 16px; color: #8b9eb3">{{
                  v.unit
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ContentLeft" style="height: companyH(275vh)">
        <MkContent titleSrcName="njTitle" titleName="待调度订单">
          <njddRight
            class="njddrigh"
:DateNow111="DateNow111"
            :TimeOptions="selectData"
            :propTitle="ddTitle"
            :propData="ddList"
            @gettodaydd="getwaitin"
            @OrderInfoButton="getOrderInfoButton"
            @getData="getData"
            :uid="2"
            :ButtonInfo="ButtonInfo"
            @getButtonInfo="getButtonInfo"
          ></njddRight>
        </MkContent>
        <MkContent
          titleSrcName="njTitle"
          titleName="已调度订单"
          style="margin-top: 38px"
        >
          <njddRight
            class="njddrigh"

            :TimeOptions="selectData"
            :propTitle="ddTitle1"
            :propData="ddList1"
            @gettodaydd="getdd"
          >
          </njddRight>
        </MkContent>
        <MkContent
          titleSrcName="njTitle"
          titleName="已完成订单"
          style="margin-top: 30px"
        >
          <njddRight
            class="njddrigh"

            :TimeOptions="selectData"
            :propTitle="ddTitle2"
            :propData="ddList2"
            @gettodaydd="getfindd"
          ></njddRight>
        </MkContent>
      </div>
    </div>
    <BottomNav
      :navData="btData"
      class="BottomNav"
      njTitle="navBottomImg"
      typeOf="njImgs"
    ></BottomNav>
    <NjddMaskBoxx
      :jcType="jcType"
      v-on="$listeners"
      @getModeData="getModeData"
      :changeMask="showMask"
      :mainTable="TableTitle"
      showNav="false"
      @getMask="closeMask"
      :NavList="NavList"
      :uid="currentid"
      :MachineList="MachineList"
    >
      <NjddMaskImg :stateParams="stateParams" :uid="1"></NjddMaskImg>
    </NjddMaskBoxx>
  </div>
</template>

<script>
//获取接口数据
import {
  getDayCount,
  getExpectOrder,
  getContrastedCondition,
  getAccumulate,
  selectAgriStatus,
  selectAgriMain,
  getJobOrderMain,
  getDispatchOrFinishOrder,
  getJobOrderInfo,
} from '../../api/njApi/njzy';
import { getWait, getNOFin } from '@/api/njApi/njzl.js';
import LeftCont from './components/njdd/LeftBox.vue';

import NjddMaskBox from '@/views/njall/components/njdd/NjddMaskTool.vue';
import NjddMaskBoxx from '@/views/njall/components/njdd/NjddMaskTool1.vue';
import NjddMaskImg from '@/views/njall/components/njdd/NjddMaskImg.vue';

import Title from '@/views/drying/components/title.vue';
import njddRight from '@/views/njall/components/njdd/TableList.vue';
// 底部导航栏
import BottomNav from '@/views/njall/components/BottomNav.vue';
import { btData } from '@/views/njall/mock/njJson.js';
import MkContent from '@/views/njall/components/ModelBg.vue';
import * as njddmock from '@/views/njall/components/js/zynj.js';
import * as ybmock from '@/views/njall/components/js/ybpChart.js';

import * as rightList from '@/views/njall/components/js/RightJson.js';
// 中间每个小标题
import MidBox from '@/views/njall/components/MiddleBox.vue';
// mock
import * as options from '@/views/njall/components/js/njToolMock.js';
// api

export default {
  components: {
    njddRight,
    MidBox,
    Title,
    BottomNav,
    MkContent,
    LeftCont,
    NjddMaskBox,
    NjddMaskImg,
    NjddMaskBoxx,
  },
  data() {
    return {
      DateNow111:null,
      address: '',
      typeList: [ {}, {}, {}, {}, {}, {} ],
      ordercount: '',
      ButtonInfo: [],
      jcType: [],
      MachineList: {},
      ordernum: 0,
      currentid: null,
      stateParams: {
        title: '正常',
        count: 0,
      },
      orderButton: '',
      selectData: [
        { id: 4, label: '全部', value: 'qb' },
        { id: 0, label: '耕整作业', value: 'gzzy' },
        { id: 1, label: '机插作业', value: 'jxzy' },
        { id: 2, label: '机收作业', value: 'jszy' },
        { id: 3, label: '植保作业', value: 'zbzy' },
      ],

      TableTitle: [
        { id: 1, prop: 'picture', label: '图片', width: '60', png: true },

        { id: 2, prop: 'model', label: '型号', width: '40' },
        {
          id: 3,
          prop: 'hasBeidou',
          label: '是否加装北斗',
          width: '100',
          png: true,
        },
        {
          id: 4,
          prop: 'carOwner',
          label: '所有人',

          width: '70',
        },

        {
          id: 5,
          prop: 'status',
          label: '设备状态',

          width: '70',
        },
      ],
      TableTitle2B: [
        { id: 1, prop: 'picture', label: '图片', width: '60', png: true },

        { id: 2, prop: 'model', label: '型号', width: '100' },
        {
          id: 3,
          prop: 'hasBeidou',
          label: '是否加装北斗',
          width: '80',
          png: true,
        },
        {
          id: 4,
          prop: 'carOwner',
          label: '所有人',

          width: '70',
        },

        {
          id: 5,
          prop: 'status',
          label: '设备状态',

          width: '70',
        },
      ],
      TableTitle1: [
        { id: 1, prop: 'jobTractorName', label: '操作手', width: '60' },
        { id: 2, prop: 'jobType', label: '作业类型', width: '70' },
        {
          id: 3,
          prop: 'jobPrice',
          label: '作业价格',
          width: '60',
          png: true,
        },
        { id: 4, prop: 'jobNum', label: '作业面积', width: '50' },
        {
          id: 5,
          prop: 'orderFinishTime',
          label: '作业时间',
          width: '100',
        },

        {
          id: 6,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
        },
      ],
      TableTitle3B: [
        { id: 1, prop: 'jobTractorName', label: '操作手', width: '70' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '90' },
        { id: 3, prop: 'jobType', label: '作业类型', width: '90' },

        { id: 4, prop: 'jobNum', label: '作业面积', width: '90' },
        {
          id: 5,
          prop: 'orderFinishTime',
          label: '作业时间',
          width: '100',
        },
      ],
      TableTitle4: [
        { id: 1, prop: 'farmerName', label: '作业对象', width: '60' },
        { id: 2, prop: 'jobType', label: '作业类型', width: '70' },

        { id: 3, prop: 'jobNum', label: '作业面积', width: '70' },
        { id: 4, label: '操作', width: '80', operate: true },
      ],
      //耕整
      TableTitle7: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'jobTractorName', label: '意向机手', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'appoint', label: '要求作业时间', width: '150' },
        // { id: 10, label: '操作', width: '100', operate: true }
      ],
      //耕整完成
      TableTitle7A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 5, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 6, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'zyTime', label: '作业时间', width: '150' },
        { id: 11, btn: true, label: '作业路径', width: '100' },
        {
          id: 12,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true,
        },
      ],
      //机收
      TableTitle5: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'jobObj', label: '作物类型', width: '100' },
        { id: 6, prop: 'jobTractorName', label: '意向机手', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'appoint', label: '要求作业时间', width: '150' },
        // { id: 10, label: '操作', width: '100', operate: true }
      ],
      //机收完成
      TableTitle5A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'jobObj', label: '作物类型', width: '100' },
        { id: 5, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 6, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'zyTime', label: '作业时间', width: '150' },
        { id: 11, btn: true, label: '作业路径', width: '100' },
        {
          id: 12,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true,
        },
      ],
      //植保
      TableTitle3: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'jobObj', label: '作物类型', width: '100' },
        { id: 5, prop: 'pesticidesSource', label: '农药来源', width: '80' },
        { id: 6, prop: 'jobTractorName', label: '意向机手', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'appoint', label: '要求作业时间', width: '150' },
        // { id: 10, label: '操作', width: '100', operate: true }
      ],
      //植保完成
      TableTitle3A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'jobObj', label: '作物类型', width: '100' },
        { id: 5, prop: 'pesticidesSource', label: '农药来源', width: '80' },
        { id: 6, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 7, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 8, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 9, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 10, prop: 'appoint', label: '作业时间', width: '150' },
        { id: 11, btn: true, label: '作业路径', width: '100' },
        {
          id: 12,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true,
        },
      ],
      //机插
      TableTitle2: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'pesticidesSource', label: '秧苗来源', width: '100' },
        { id: 6, prop: 'jobTractorName', label: '意向机手', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'appoint', label: '要求作业时间', width: '150' },
        // { id: 10, label: '操作', width: '100', operate: true }
      ],
      //机插完成
      TableTitle2A: [
        { id: 1, prop: 'serialNum', label: '序号', width: '60' },
        { id: 2, prop: 'farmerName', label: '作业对象', width: '100' },
        { id: 3, prop: 'farmerTel', label: '农户电话', width: '100' },
        { id: 4, prop: 'pesticidesSource', label: '秧苗来源', width: '100' },
        { id: 5, prop: 'jobTractorName', label: '操作手', width: '80' },
        { id: 6, prop: 'jobTractorTel', label: '机手电话', width: '80' },
        { id: 7, prop: 'jobPrice', label: '作业价格', width: '80' },
        { id: 8, prop: 'jobNum', label: '作业面积', width: '100' },
        { id: 9, prop: 'zyTime', label: '作业时间', width: '150' },
        { id: 10, btn: true, label: '作业路径', width: '100' },
        {
          id: 11,
          prop: 'orderEvaluate',
          label: '评价',
          width: '100',
          pj: true,
        },
      ],
      ...rightList,
      //对比上年情况
      ...ybmock,
      ...njddmock,
      ...options,
      currentName: '耕整作业',
      nowYear: '',
      lastYear: '',
      btData: btData,
      timer: null,
      showMask: false,
      MidData: [
        {
          id: 1,
          title: '待调度农机',
          data: 0,
          unit: '台',
        },
        {
          id: 2,
          title: '已调度农机',
          data: 0,
          unit: '台',
        },
        {
          id: 3,
          title: '未完成订单',
          data: 0,
          unit: '单',
        },
        {
          id: 4,
          title: '已完成订单',
          data: 0,
          unit: '单',
        },
      ],
      njddBtnData: [
        { id: 'gzzy', title: '耕整作业' },
        { id: 'cyzy', title: '机插作业' },
        { id: 'sgzy', title: '机收作业' },
        { id: 'zbzy', title: '植保作业' },
      ],
      currentTime: 2023,
      TimeOptions: [
        {
          value: '2023',
          label: '2023',
        },
        {
          value: '2022',
          label: '2022',
        },
      ],
      bottomBtn: [
        {
          imgSrc: require('@/assets/njImgs/njddBottom.png'),
          title: '今日完成订单',
          value: 0,
          unit: '亩',
          ds: 0,
        },
        {
          imgSrc: require('@/assets/njImgs/njddBottom.png'),
          title: '今日新增订单',
          value: 0,
          unit: '亩',
          ds: 0,
        },
        {
          imgSrc: require('@/assets/njImgs/njddBottom.png'),
          title: '紧急订单',
          value: 0,
          unit: '亩',
          ds: 0,
        },
      ],
    };
  },
  created() {
    clearInterval(this.timer); // 清除定时器
    let year = new Date().getFullYear();
    this.currentTime = year;
    this.TimeOptions = [];
    for (let i = 0; i < 4; i++) {
      this.TimeOptions.unshift({
        value: year - i,
        label: (year - i).toString(),
      });
    }
    this.getfindd('');
    this.getdd('');
    this.getwaitin('');
    this.getButtonInfo('');
  },
  mounted() {

    this.address = localStorage.getItem('address');
    this.getData();
    this.getlastyear('耕整作业');
    this.getNjDdjl(2024);
    this.timer = setInterval(() => {
      setTimeout(
        this.getfindd(''),
        this.getdd(''),
        this.getwaitin(''),
        this.getData(),
        this.getButtonInfo(''),

        0
      );
    }, 1000 * 20);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
  },
  methods: {
    ljYearChange(e) {
      this.getNjDdjl(e);
    },
    // 对比上年情况
    currentNameChange(e) {
      this.currentName = e;
      this.getlastyear(e);
    },
    // 获取接口数据(对比上年情况)
    getlastyear(e) {
      getContrastedCondition({ jobType: e }).then(res => {
        this.nowYear = res.data.thisArea;
        this.lastYear = res.data.lastArea;
        if (res.code == 1) {
          // console.log(res.data, 'data');
          let data;
          let color;
          if (res.data.lastArea !== 0) {
            data = Math.floor((res.data.thisArea / res.data.lastArea) * 100);
            color =
              Math.floor((res.data.thisArea / res.data.lastArea) * 100) >= 100
                ? 99
                : Math.floor((res.data.thisArea / res.data.lastArea) * 100);
          } else if (res.data.lastArea == 0 && res.data.thisArea !== 0) {
            data = 100;
            color = 99;
          } else {
            data = 0;
            color = 0;
          }
          this.dbsnOption.title[0].text = `{a|${ data }}{b|%}`;
          this.dbsnOption.series[0].axisLine.lineStyle.color[1] = [
            color / 100,
            'rgba(32,187,252,0.15)',
          ];
        } else {
          this.dbsnOption.title[0].text = `{a|${ 0 }}{b|%}`;
          this.dbsnOption.series[0].axisLine.lineStyle.color[1] = [
            0 / 100,
            'rgba(32,187,252,0.15)',
          ];
        }
      });
    },
    // 根据年份获取农机调度累计订单量
    async getNjDdjl(e) {
      await getAccumulate({
        year: e.toString(),
      }).then(res => {
        if (res.code == 1) {
          this.ordernum = res.data.ordernum;
          this.ordercount = res.data.ordercount;
        } else {
          this.ordernum = 0;
          this.ordercount = 0;
        }
      });
    },
    //获取接口数据
    async getData() {
      //作业能力
      await getDayCount().then(res => {
        if (res.code == 1) {
          let datas = [
            {
              name: '耕整作业',
              xm: '耕整机',
              value: Math.floor(
                ((res.data.tillageCount - res.data.tillageDamage) /
                  res.data.tillageCount) *
                  100
              ),
              value1: res.data.tillageCount,
              value3: res.data.tillageDamage,
              value2: `${ res.data.tillage }亩/天`,
            },
            {
              name: '插秧作业',
              xm: '插秧机',
              value: Math.floor(
                ((res.data.transplantCount - res.data.transplantDamage) /
                  res.data.transplantCount) *
                  100
              ),
              value1: res.data.transplantCount,
              value3: res.data.transplantDamage,
              value2: `${ res.data.transplant }亩/天`,
            },
            {
              name: '收割作业',
              xm: '收割机',
              value: Math.floor(
                ((res.data.reapCount - res.data.reapDamage) /
                  res.data.reapCount) *
                  100
              ),
              value1: res.data.reapCount,
              value3: res.data.reapDamage,
              value2: `${ res.data.reap }亩/天`,
            },
            {
              name: '植保作业',
              xm: '植保机',
              value: Math.floor(
                ((res.data.protectionCount - res.data.protectionDamage) /
                  res.data.protectionCount) *
                  100
              ),
              value1: res.data.protectionCount,
              value3: res.data.protectionDamage,
              value2: `${ res.data.protection }亩/天`,
            },
          ];
          this.fwnjOption.yAxis[0].data = datas.map(item => {
            return ` ${ item.value1 }台${
              item.value == '100' ? '' : `(其中损坏${ item.value3 }台)`
            }`;
          });
          this.fwnjOption.yAxis[1].data = datas
            .map(item => item.name)
            .reverse();
          this.fwnjOption.yAxis[2].data = datas
            .map(item => item.value2)
            .reverse();
          this.fwnjOption.series[0].data = datas
            .map(item => item.value)
            .reverse();
        }
      });
      //需求情况 rgzOption
      await getExpectOrder().then(res => {
        if (res.code == 1) {
          let totol =
            res.data.threeOrder + res.data.fourOrder + res.data.sevenOrder;
          let list = [
            { name: '3天内订单', value: res.data.threeOrder },
            { name: '4~7天内订单', value: res.data.fourOrder },
            { name: '7天以上订单', value: res.data.sevenOrder },
          ];
          this.rgzOption.series[0].data = list;
          this.rgzOption.title.text = totol;
          this.rgzOption.legend[0].formatter = params => {
            let value;
            list.forEach(v => {
              if (v.name == params) {
                value = v.value;
              }
            });
            return `{a|${ params }}  {b|${ value }}`;
          };
        }
      });
      //获得农机数量
      await selectAgriMain().then(res => {
        if (res.code == 1) {
          // console.log(res.data,'456456456456456');
          this.MidData[0].data = res.data.freeCount;
          this.MidData[1].data = res.data.workCount;
        }
      });
      //获得订单数量
      await getJobOrderMain().then(res => {
        if (res.code == 1) {
          this.bottomBtn[0].value = res.data.todayFinishOrderAcre;
          this.bottomBtn[0].ds = res.data.todayFinishOrderCount;
          this.bottomBtn[1].value = res.data.todayOrderAcre;
          this.bottomBtn[1].ds = res.data.todayOrderCount;
          this.bottomBtn[2].value = res.data.rushOrderAcre;
          this.bottomBtn[2].ds = res.data.rushOrderCount;
          // console.log(res.data,'456456456456456');
          this.MidData[2].data = res.data.unFinishOrderCount;
          this.MidData[3].data = res.data.finishOrderCount;
        }
      });
      await selectAgriStatus({
        status: '空闲',
        pageNum: 1,
        pageSize: 10,
      }).then(res => {
        if (res.code == 1) {
          this.typeList[0] = res.data;
          // let qb = {
          //   car_type:'全部',
          //   COUNT:0
          // }
          // if (res.data) {
          //   res.data.forEach(ele => {
          //     qb.COUNT += ele.COUNT;
          //   });
          // }
          // this.typeList[0].unshift(qb)
        }
      });
      await selectAgriStatus({
        status: '工作',
        pageNum: 1,
        pageSize: 10,
      }).then(res => {
        if (res.code == 1) {
          this.typeList[1] = res.data;
          // let qb = {
          //   car_type:'全部',
          //   COUNT:0
          // }
          // if (res.data) {
          //   res.data.forEach(ele => {
          //     qb.COUNT += ele.COUNT;
          //   });
          // }
          // this.typeList[1].unshift(qb)
        }
      });
      await getJobOrderInfo({
        status: 'finish',
        pageNum: 1,
        pageSize: 10,
      }).then(res => {
        if (res.code == 1) {
          // this.bottomBtn[0].value = res.data[res.data.length-1].jobNum
          this.typeList[3] = res.data;
          this.typeList[3].unshift(res.data[res.data.length - 1]);
          this.typeList[3].pop(res.data[res.data.length - 1]);
        } else {
          // this.bottomBtn[0].value = 0
        }
      });
      await getJobOrderInfo({
        status: 'today',
        pageNum: 1,
        pageSize: 10,
      }).then(res => {
        if (res.code == 1) {
          // this.bottomBtn[1].value = res.data[res.data.length-1].jobNum
          this.typeList[4] = res.data;
          this.typeList[4].unshift(res.data[res.data.length - 1]);
          this.typeList[4].pop(res.data[res.data.length - 1]);
        } else {
          // this.bottomBtn[1].value=0
        }
      });
      await getJobOrderInfo({
        status: 'rush',
        pageNum: 1,
        pageSize: 10,
      }).then(res => {
        if (res.code == 1) {
          // this.bottomBtn[2].value = res.data[res.data.length-1].jobNum
          this.typeList[5] = res.data;
          this.typeList[5].unshift(res.data[res.data.length - 1]);
          this.typeList[5].pop(res.data[res.data.length - 1]);
        } else {
          // this.bottomBtn[2].value=0
        }
      });
    },
    //获取弹窗数据
    async getModeData(obj) {
      if (obj.status == '工作' || obj.status == '空闲') {
        this.TableTitle = this.TableTitle2B;
        if (obj.status == '空闲') {
          this.jcType = this.typeList[0];
          this.stateParams = {
            title: '空闲',
            count: this.jcType[0].count,
          };
        } else {
          this.jcType = this.typeList[1];
          this.stateParams = {
            title: '工作',
            count: this.jcType[0].count,
          };
        }
        await selectAgriStatus(obj).then(res => {
          console.log(res, '1111');
          if (res.code == 1) {
            this.MachineList = res.data;
            console.log(this.MachineList);
          }
        });
      } else if (obj.status == '未完成') {
        switch (obj.jobType) {
        case '耕整作业':
          this.TableTitle = this.TableTitle7;
          break;
        case '机收作业':
          this.TableTitle = this.TableTitle5;
          break;
        case '植保作业':
          this.TableTitle = this.TableTitle3;
          break;
        case '机插作业':
          this.TableTitle = this.TableTitle2;
          break;
        }
        // this.jcType = this.typeList[2]
        await getDispatchOrFinishOrder(obj).then(res => {
          if (res.code == 1) {
            this.MachineList = res.data;
            this.MachineList.records = res.data.records.map((item, index) => {
              return Object.assign(item, {
                serialNum: (obj.pageNum - 1) * obj.pageSize + index + 1,
                appoint: item.appointmentStart
                  ? `${ item.appointmentStart.slice(0, 10) }${
                    item.startTime
                  }~${ item.appointmentEnd.slice(0, 10) }${
                    item.endTime
                  }`.replace(/-/g, '/')
                  : '',
                farmer: item.farmerName
                  ? `${ item.farmerName }${
                    item.jobFieldName ? `-${ item.jobFieldName }` : ''
                  }`
                  : '',
                jobPrice: item.jobPrice ? `${ item.jobPrice }元/亩` : '',
                jobNum: item.jobNum ? `${ item.jobNum }亩` : '',
                seedingPrice: item.seedingPrice
                  ? `${ item.seedingPrice }元/盘`
                  : '',
                seedingNum: item.seedingNum ? `${ item.seedingNum }盘` : '',
                orderFinishTime: item.orderFinishTime
                  ? `${ item.orderFinishTime.slice(0, 10) }`
                  : '',
                zyTime: item.orderFinishTime
                  ? `${ item.orderStartTime.slice(
                    0,
                    10
                  ) }~${ item.orderFinishTime.slice(0, 10) }`.replace(/-/g, '/')
                  : '',
                dryingCount: item.dryingCount ? `${ item.dryingCount }吨` : '',
                outTower: item.outTower ? `${ item.outTower }吨` : '',
              });
            });
          }
        });
      } else if (obj.status == '已完成') {
        switch (obj.jobType) {
        case '耕整作业':
          this.TableTitle = this.TableTitle7A;
          break;
        case '机收作业':
          this.TableTitle = this.TableTitle5A;
          break;
        case '植保作业':
          this.TableTitle = this.TableTitle3A;
          break;
        case '机插作业':
          this.TableTitle = this.TableTitle2A;
          break;
        }
        // this.jcType = this.typeList[2]
        await getDispatchOrFinishOrder(obj).then(res => {
          if (res.code == 1) {
            this.MachineList = res.data;

            this.MachineList.records = res.data.records.map((item, index) => {
              return Object.assign(item, {
                serialNum: (obj.pageNum - 1) * obj.pageSize + index + 1,
                appoint: item.appointmentStart
                  ? `${ item.appointmentStart.slice(0, 10) }${
                    item.startTime
                  }~${ item.appointmentEnd.slice(0, 10) }${
                    item.endTime
                  }`.replace(/-/g, '/')
                  : '',
                farmer: item.farmerName
                  ? `${ item.farmerName }${
                    item.jobFieldName ? `-${ item.jobFieldName }` : ''
                  }`
                  : '',
                jobPrice: item.jobPrice ? `${ item.jobPrice }元/亩` : '',
                jobNum: item.jobNum ? `${ item.jobNum }亩` : '',
                seedingPrice: item.seedingPrice
                  ? `${ item.seedingPrice }元/盘`
                  : '',
                seedingNum: item.seedingNum ? `${ item.seedingNum }盘` : '',
                orderFinishTime2: item.orderFinishTime
                  ? `${ item.orderFinishTime.slice(0, 10) }`
                  : '',
                zyTime: item.orderFinishTime
                  ? `${ item.orderStartTime.slice(
                    0,
                    10
                  ) }~${ item.orderFinishTime.slice(0, 10) }`.replace(/-/g, '/')
                  : '',
                dryingCount: item.dryingCount ? `${ item.dryingCount }吨` : '',
                outTower: item.outTower ? `${ item.outTower }吨` : '',
              });
            });
          }
        });
      } else if (obj.status == 'finish') {
        this.TableTitle = this.TableTitle3B;
        this.jcType = this.typeList[3];
        await getJobOrderInfo(obj).then(res => {
          if (res.code == 1) {
            this.MachineList = res.data;
            this.MachineList.records = res.data.records.map(item => {
              return Object.assign(item, {
                jobPrice: item.jobPrice ? `${ item.jobPrice }元/亩` : '',
                jobNum: item.jobNum ? `${ item.jobNum }亩` : '',
                orderFinishTime: item.orderFinishTime
                  ? item.orderFinishTime.slice(0, 10)
                  : '',
              });
            });
          }
        });
      } else if (obj.status == 'today') {
        this.TableTitle = this.TableTitle3B;
        this.jcType = this.typeList[4];
        await getJobOrderInfo(obj).then(res => {
          if (res.code == 1) {
            this.MachineList = res.data;
            this.MachineList.records = res.data.records.map(item => {
              return Object.assign(item, {
                jobPrice: item.jobPrice ? `${ item.jobPrice }元/亩` : '',
                jobNum: item.jobNum ? `${ item.jobNum }亩` : '',
                orderFinishTime: item.orderFinishTime
                  ? item.orderFinishTime.slice(0, 10)
                  : '待作业',
              });
            });
          }
        });
      } else if (obj.status == 'rush') {
        this.jcType = this.typeList[5];
        this.TableTitle = this.TableTitle4;
        await getJobOrderInfo(obj).then(res => {
          if (res.code == 1) {
            this.MachineList = res.data;
            this.MachineList.records = res.data.records.map(item => {
              return Object.assign(item, {
                jobPrice: item.jobPrice ? `${ item.jobPrice }元/亩` : '',
                jobNum: item.jobNum ? `${ item.jobNum }亩` : '',
                orderFinishTime: item.orderFinishTime
                  ? item.orderFinishTime.slice(0, 10)
                  : '',
              });
            });
          }
        });
      }
    },
    showDetail(id, typeofs) {
      if (id < 5 && !this.MidData[id - 1].data) return;
      if (id > 4 && !this.bottomBtn[id - 5].value) return;

      let obj = {
        jobType: '耕整作业',
        pageNum: 1,
        pageSize: 10,
      };
      if (typeofs == 'left') {
        this.isJbxx = 'jbxx';
        if (id === 1) {
          obj = {
            carType: '',
            status: '空闲',
            pageNum: 1,
            pageSize: 5,
          };
          this.currentid = id;
          this.showMask = true;
          this.getModeData(obj);
        } else if (id === 2) {
          obj = {
            carType: '',
            status: '工作',
            pageNum: 1,
            pageSize: 5,
          };
          this.currentid = id;
          this.showMask = true;
          this.getModeData(obj);
        } else if (id === 3) {
          obj = {
            status: '未完成',
            pageNum: 1,
            pageSize: 5,
            jobType: '耕整作业',
          };

          this.currentid = id;
          this.showMask = true;
          this.getModeData(obj);
        } else if (id === 4) {
          obj = {
            status: '已完成',
            pageNum: 1,
            pageSize: 5,
            jobType: '耕整作业',
          };
          this.currentid = id;
          this.showMask = true;
          this.getModeData(obj);
        } else if (id === 5) {
          obj = {
            jobType: '',
            status: 'finish',
            pageNum: 1,
            pageSize: 10,
          };
          this.currentid = id;
          this.showMask = true;
          this.getModeData(obj);
        } else if (id === 6) {
          obj = {
            jobType: '',
            status: 'today',
            pageNum: 1,
            pageSize: 10,
          };
          this.currentid = id;
          this.showMask = true;
          this.getModeData(obj);
        } else if (id === 7) {
          obj = {
            jobType: '',
            status: 'rush',
            pageNum: 1,
            pageSize: 10,
          };
          this.currentid = id;
          this.showMask = true;
          this.getModeData(obj);
        }
      } else {
        if (!this.stateParams.count) return;
        this.isJbxx = '';
        this.showMask = true;
      }
    },
    closeMask(item) {
      this.showMask = item;
    },
    changeMas(params) {
      this.showDetail(params.id, params.typeof);
    },

    async getfindd(jobType) {
      const { data } = await getNOFin({
        status: '已完成',
        jobType,
        pageSize: 100,
      });
      this.ddList2 = data.records.map(item => {
        return {
          id: item.id,
          lb: item.jobType.slice(0, 1),
          name: `对象： ${ item.farmerName }-${ item.jobNum }亩`,
          content: `机手： ${ item.jobTractorName }`,
          btn: '查看订单',
        };
      });
    },

    async getdd(jobType) {
      const { data } = await getNOFin({
        status: '已调度',
        jobType,
        pageSize: 100,
      });

      this.ddList1 = data.records.map(item => {
        return {
          id: item.id,
          lb: item.jobType.slice(0, 1),
          name: `对象： ${ item.farmerName }-${ item.jobNum }亩`,

          content: `机手： ${ item.jobTractorName }`,
          btn: '重新调度',
        };
      });
    },
    async getwaitin(jobType) {
      this.orderButton = jobType;
      const { data } = await getWait({ jobType, day: this.DateNow111, pageSize: 100 });

      this.ddList = data.records.map(item => {
        return {
          id: item.id,
          lb: item.jobType.slice(0, 1),
          name: `对象： ${ item.farmerName }`,
          content: `面积： ${ item.jobNum }亩`,
          btn: '分配订单',
        };
      });
    },
    async getButtonInfo() {
      const { data } = await getWait({ jobType: '', pageSize: 100 });
      console.log(data);
      const sortedData = data.slice().sort((a, b) => a.day - b.day);
      console.log(sortedData);
      this.ButtonInfo = sortedData;
    },
    async getOrderInfoButton(e) {
      const { data } = await getWait({ jobType: this.orderButton, day: e });

      this.ddList = data.records.map(item => {
        return {
          id: item.id,
          lb: item.jobType.slice(0, 1),
          name: `对象： ${ item.farmerName }`,
          content: `面积： ${ item.jobNum }亩`,
          btn: '分配订单',
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}

::v-deep [data-v-4aa266b6] .midN .el-input__inner {
  width: companyW(100vw);
  height: companyH(35vh);
  font-size: companyW(18vw);
}

::v-deep .el-select__caret {
  margin-right: -10px !important;
}

.njServe {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  background-image: url(../../assets/njImgs/njddBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: companyW(32vw);
  padding-right: companyW(32vw);

  .njTitle {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  .ContentBox {
    max-width: companyW(1880vw);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .ContentMiddle {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: companyH(40vh) companyW(12vw);

      .topCount {
        background: rgba(0, 53, 178, 0.3);
        border: 2px solid;
        border-image: linear-gradient(0deg, #24c4ff, #0057c2) 10 10;
        border-radius: 3px;
      }
    }

    .ContentLeft {
      width: companyW(470vw);
    }
  }

  .BottomNav {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.njTopBox {
  box-sizing: border-box;
  width: companyW(460vw);
  margin-top: companyH(-12vh);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #03171f;
  background-color: rgba($color: #000000, $alpha: 0.3);
  position: relative;
}

.midN {
  flex: 1;
  padding: companyH(56vh) companyW(24vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .sjjk {
    display: flex;
    justify-content: center;
    align-item: center;

    .centerTitle {
      font-size: companyH(35vh);
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #ffffff;
    }

    .centerSj {
      font-size: companyH(50vh);
      font-family: DIN;
      font-weight: 400;
      color: #00ffd4;
      margin-top: companyH(20vh);
      background: linear-gradient(0deg, #2cf4d1 0%, #22d0e5 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .midN-div {
    display: flex;
    justify-content: space-between;

    .midBottom-One {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: companyW(56vw);
        height: companyH(56vh);
      }

      .leftMargin {
        margin-left: companyW(14vw);
        display: flex;
        flex-direction: column;

        span {
          margin: 0 companyW(3vw);
        }

        .hang {
          font-size: companyW(30vw);
        }
      }
    }
  }
}

.boxTitle,
.order {
  font-size: companyH(16vh);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #f8feff;
}

.order {
  color: #ff0000;
}

.orderValue,
.normal {
  font-size: companyH(30vh);
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fff;
}

.orderValue {
  color: #ff0000;
}

.midN-div:nth-child(3) {
  width: 100%;
  margin: 0 auto;
  margin-bottom: companyH(40vh);
}

.midPosition {
  position: absolute;
  right: companyW(160vw);
  top: companyH(210vh);
  z-index: 3;
}

::v-deep .midN {
  .el-input {
    width: companyW(76vw);
  }

  .el-input__inner {
    height: companyH(27.4vh);
    background: rgba(6, 40, 62, 0.9);
    border-radius: 0;
    border: #00def0 companyW(1vw) solid;
    font-size: companyH(11.35vh);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #e1eaea;
  }
}

.middleBox {
  display: flex;
  flex-direction: column;
  flex: 1;

  .middleTop {
    display: flex;
    align-items: center;
    margin: companyH(40vh) auto;
    margin-bottom: companyH(10vh);

    .topFont {
      font-size: companyH(24vh);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      margin: 0 companyW(6vw);
    }

    .topCount {
      width: companyW(36vw);
      background: rgba(0, 53, 178, 0.3);
      border: companyH(2vh) solid;
      border-image: linear-gradient(0deg, #24c4ff, #0057c2) 10 10;
      border-radius: companyW(3vw);
      text-align: center;
      line-height: companyH(48vh);
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      margin: 0 companyW(4vw);
    }
  }
}

.njddrigh {
  overflow: auto;
  height: companyH(260vh);
}

.njddrigh::-webkit-scrollbar {
  width: 1px;
  /* 设置滚动条的宽度 */
  background-color: transparent;
  /* 设置滚动条的背景色为透明 */
}

.njddrigh::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* 设置滚动条的 thumb 的背景色为透明 */
}

.dbsnClass {
  display: flex;
  flex-direction: column;

  .dbsnTop {
    width: 90%;
    margin: companyH(15vh) auto;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;

    div {
      padding: companyW(10vw) companyH(21vh);
      background: rgba(55, 57, 66, 0.54);
      border: companyW(1vw) solid #009bed;
      font-size: companyH(14vh);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #009bed;
      cursor: pointer;
    }
  }

  .lastYear-content-top {
    margin-top: companyH(93vh);
    margin-right: companyW(50vw);
    width: companyW(217vw);
    height: companyH(161vh);

    // margin-top: ;
    .content-top-item {
      width: companyW(150vw);
      // height: companyH(60vh);

      .top-item-name {
        margin-left: companyW(5vw);
        display: inline-block;
        margin-bottom: companyH(7vh);
        height: companyH(13vh);
        font-size: companyH(16vh);
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #eff0f1;
        line-height: companyH(17vh);
      }

      .little-box {
        display: inline-block;
        width: companyW(6vw);
        height: companyH(8vh);
        background-color: #009bed;
      }

      .little-box-red {
        display: inline-block;
        width: companyW(6vw);
        height: companyH(8vh);
        background-color: #00f5e1;
      }

      .top-item-number {
        margin-top: companyH(3vh);

        margin-left: companyW(7vw);
        width: 100%;
        height: companyH(47vh);

        background-size: cover;
        font-size: companyH(16vh);
        font-family: DIN;
        font-weight: 700;
        color: #ffffff;
        line-height: companyH(47vh);
      }

      .top-item-number-red {
        margin-top: companyH(3vh);
        margin-left: companyW(7vw);

        width: 100%;
        height: companyH(47vh);

        background-size: cover;
        font-size: companyH(16vh);
        font-family: DIN;
        font-weight: 700;
        color: #00f5e1;
        line-height: companyH(47vh);
      }
    }
  }
}

::v-deep .el-select .el-input .el-select__caret {
  line-height: companyH(25vh);
}

.el-select-dropdown__item {
  color: #606266 !important;
}
</style>
