<template>
  <div>
    <div id="map" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
export default {
  props: {
    mapPoint: {
      type: Array
    }
  },
  mounted () {
    this.initMap();
    console.log(this.mapPoint);
  },
  methods: {
    initMap () {
      // 初始化地图对象
      const map = new T.Map('map');

      // 设置地图中心点和缩放级别
      map.centerAndZoom(new T.LngLat(this.mapPoint[0], this.mapPoint[1]), 18);

      // 添加标注
      const marker = new T.Marker(
        new T.LngLat(this.mapPoint[0], this.mapPoint[1])
      );
      map.addOverLay(marker);
    }
  }
};
</script>

<style></style>
