<template>
  <div>
    <el-dialog :visible.sync="chaMask" :show-close="false" @close="closeMask">
      <dv-border-box-1>
        
        <div class="ToolBox" v-if="uid != 4">
          <slot></slot>
          <div class="NavList" v-if="NavList.length != 0">
            <div
              :class="
                item.id == currentInd
                  ? `${getClass()} currentNav`
                  : `${getClass()}`
              "
              v-for="item in NavList"
              :key="item.id"
              @click="changeNav(item.id)"
            >
              <div class="NavTitle">{{ item.label }}</div>
              <div class="NavTitle">
                <span>{{ item.value }}</span>
                <span class="NavSpan">{{ item.unit }}</span>
              </div>
            </div>
          </div>
          <!-- 待调度农机 -->

          <el-table
            stripe
            v-loading="tabLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="MachineList"
            style="width: 100% margin 0 auto"
            v-if="uid === 1"
          >
            <template v-for="v in mainTable">
              <el-table-column
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
                @cell-mouse-enter="ent"
              >
                <!-- <template slot-scope="scope">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="scope.row[v.prop]"
                  ></el-image>
                </template> -->
              </el-table-column>
            </template>

          </el-table>
          <!-- 已调度农机 -->
          <el-table

            stripe
            v-loading="tabLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="MachineList2"
            style="width: 100% margin 0 auto"
            v-if="uid===2"
          >
            <el-table-column type="index" label="序号" width="70" align:center>
            </el-table-column>
            <el-table-column prop="model" label="型号" width="100" align:center>
            </el-table-column>
            <el-table-column
              prop="isBeidou"
              label="是否加装北斗"
              width="140"
              align:center
            >
            </el-table-column>
            <el-table-column
              prop="WhoHas"
              label="所有人"
              width="100"
              align:center
            >
            </el-table-column>
            <el-table-column
              prop="DeviceStatus"
              label="设备状态"
              width="120"
              align:center
            >
            </el-table-column>
            <el-table-column

              label="位置"
              width="120"
              align:center
            >
            <template v-slot="{row}">
            <span style="font-size: 14px;">查看位置</span>
            </template>
            </el-table-column>

          </el-table>

        </div>
        <div class="toolBox_finish" v-else>
          <div class="leftBox">

          <!-- 已完成订单 -->

            <template>
              <el-table
                stripe
                v-loading="tabLoading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="tableList"
                style="width: 100%"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="60%"
                  align:center
                >
                </el-table-column>
                <el-table-column
                  prop="user"
                  label="操作手"
                  width="90%"
                  align:center
                >
                </el-table-column>
                <el-table-column
                  prop="workType"
                  label="作业类型"
                  width="100%"
                  align:center
                >
                </el-table-column>
                <el-table-column
                  prop="price"
                  label="作业价格"
                  width="100%"
                  align:center
                >
                </el-table-column>
                <el-table-column
                  prop="workArea"
                  label="作业面积"
                  width="100%"
                  align:center
                >
                </el-table-column>
                <el-table-column
                  prop="workTime"
                  label="作业时间"
                  width="130%"
                  align:center
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="评价"
                  width="150%"
                  align:center
                >
                  <template v-slot="{ row }">
                    <el-rate
                      v-model="row.address"
                      disabled="true"
                      :colors="['#009ad6', '#009ad6', '#009ad6']"
                      disabled-void-color="#77787b"
                    ></el-rate>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="queryObj.pageSize"
                layout="total, prev, pager, next"
                :total="tableList.length"
                v-if="uid === 4"
              >
              </el-pagination>
            </template>
          </div>
          <div class="rightBox" v-if="uid === 4">
            <div class="mapTitle">作业路径</div>
            <div class="TrackMap">
              <Tmap></Tmap>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>
// import MidChart from '@/views/njall/components/njzl/ToolChart.vue';
// import pagination from '@/components/pagination/pagination.vue';
import {
  tableList,
  MachineList,
  MachineList2
} from '@/views/njall/mock/zyfwMock/zyfwToolMock.js';
import Tmap from '@/views/njall/components/TMap/map.vue';
export default {
  components: {
    // MidChart,
    Tmap,
    // pagination
  },
  props: {
    changeMask: {
      type: Boolean,
      default: false
    },
    mainTable: {
      type: Array,
      default: () => []
    },
    NavList: {
      type: Array,
      default: () => []
    },
    TanleContent: {
      type: Array,
      default: () => []
    },
    uid: {
      type: Number
    }
  },
  data () {
    return {
      tableList,
      MachineList,
      MachineList2,
      total: 400,
      tabLoading: true,
      currentInd: 1,
      // ...options,
      chaMask: false,
      rateColor: [ '#009ad6' ],
      queryObj: {
        currentPage: 1,
        pageSize: 10
      }
    };
  },
  watch: {
    changeMask: {
      handler (newv) {
        this.chaMask = newv;
      }
    }
  },

  methods: {
    getClass () {
      if (this.uid === 1) return 'NavOne';
      else return 'NavTwo';
    },
    handleSizeChange (val) {
      console.log(`每页 ${ val } 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${ val }`);
    },
    closeMask () {
      this.chaMask = false;
      this.$emit('getMask', false);
    },
    changeNav (id) {
      this.tabLoading = true;
      this.currentInd = id;
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    ent () {}
  },

  mounted () {


    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
    console.log(this.chaMask);
  },
  computed: {
    totalText () {
      return `共 ${ this.total } 条记录`;
    },
    changeNavClass () {
      return uid => {
        const className = this.map.get(uid);
        if (className) {
          return className;
        }
        return 'NavOne';
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}

::v-deep .el-dialog {
  width: companyW(1499vw);
  background: #000000;
  opacity: 0.9;
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.ToolBox {
  width: 95%;
  // height: companyH(605vh);
  margin: 0 auto;
  padding: companyH(88vh) 0;
  display: flex;

  .NavList {
    display: flex;
    flex-direction: column;
    margin-right: companyW(10vw);

    .NavTwo {
      width: companyW(182vw);
      height: companyH(53vh);

      background-image: url(../../../../assets//njImgs//toolNav_green.png);
      // background-image: url(../../../../assets//njImgs//toolNav.png);

      background-size: cover;
      margin-bottom: companyH(10vh);
      opacity: 0.3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #33f8f8;
      cursor: pointer;

      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(15.2vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #33f8f8;

        .NavSpan {
          font-size: companyH(12vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
    }
    .NavOne {
      width: companyW(182vw);
      height: companyH(53vh);

      // background-image: url(../../../../assets//njImgs//toolNav_green.png);
      background-image: url(../../../../assets//njImgs//toolNav.png);

      background-size: cover;
      margin-bottom: companyH(10vh);
      opacity: 0.3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #33f8f8;
      cursor: pointer;

      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(15.2vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #33f8f8;

        .NavSpan {
          font-size: companyH(12vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
    }
  }
}

.NavOne:hover {
  opacity: 0.7 !important;
  transition: all 0.5s;
}

.currentNav {
  opacity: 1 !important;
}

::v-deep .el-table th {
  background: #1b4069;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}

::v-deep .cell {
  font-size: companyH(14vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #71cdf9;
}

::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}

::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}

.toolStyle {
  color: '#33f8f8' !important;
  font-size: companyH(14vh);
  text-decoration: underline;
  cursor: pointer;
}

.toolBox_finish {
  display: flex;

  // justify-content: space-between;

  .leftBox {
    box-sizing: border-box;
    margin-left: companyW(70vw);
    margin-top: companyH(80vh);
    width: 49%;
  }

  .rightBox {
    box-sizing: border-box;

    margin-right: companyW(110vw);
    margin-bottom: companyH(125vh);

    width: companyW(522vw);
    height: companyH(554vh);

    margin-top: companyH(55vh);
    margin-left: companyW(100vw);
    .mapTitle {
      color: #74cfff;
    }
    .TrackMap {
      margin-top: companyH(12vh);
      width: companyW(500vw);
      height: companyH(450vh);
      background-color: #fff;
    }
  }
}

.el-pagination {
  margin: 0 auto;
  box-sizing: border-box;
  margin-left: companyW(100vw);
  margin-top: companyW(30vh);
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  background: transparent !important;
  background-color: transparent !important;
  border: companyW(1vw) solid #00a8ff !important;
}

::v-deep .el-icon-arrow-left:before {
  color: #00a8ff;
}

::v-deep .el-pagination__total {
  color: #00a8ff;
}

::v-deep .el-pager li {
  background-color: transparent !important;
  // background-color: black;
  color: #00a8ff;
  border: #00a8ff solid 1px;
}

.paginationClass{
  width: 100%;
  height: 100%;
}
</style>
