<template>
  <div>
    <el-dialog
      :visible="Visible"
      :show-close="false"
      @close="closeMask"
      @open="open"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-if="Visible"
    >
      <dv-border-box-1>
        <div class="toolbox">
          <div class="close" @click="closeMask"></div>

          <div class="titleBox">
            <div class="leftTitle">订单信息</div>
            <div class="rightTitle" v-if="currentLabel === 'distributionBtn'">
              分配订单
            </div>
            <div class="rightTitle" v-else-if="currentLabel === 'EdiotBtn'">
              修改订单
            </div>
            <div class="rightTitle" v-else>作业信息</div>
          </div>
          <div class="bodyBox">
            <div class="bodyLeft">
              <div class="txtClass">
                <div class="z-img">作业对象</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.farmerName }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">联系方式</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.farmerTel }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">作业类型</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.jobType }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">作业面积</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.jobNum }}亩</div>
              </div>

              <div class="txtclass2">
                <div class="z-img">作业位置</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">
                  {{ OrderInfo?.jobLocation || '暂无数据' }}

                  <span
                    @click="openDiaMap()"
                    style="font-size: 12px; margin-left: 10px; color: #00a8ff"
                  >
                    查看位置</span
                  >
                </div>
              </div>
              <div class="txtClass">
                <div class="z-img">下单时间</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">{{ OrderInfo?.orderTime }}</div>
              </div>
              <div class="txtClass">
                <div class="z-img">作业时间</div>
                <img src="../../../../assets/njImgs/tetBackground.png" alt="" />

                <div class="textCss">
                  {{ OrderInfo?.appointmentStart }}~{{
                    OrderInfo?.appointmentEnd
                  }}
                </div>
              </div>
              <div class="Eidot">
                <!-- <button class="submit_Eidot" v-if="currentLabel !== 'viewBtn'">
                  编辑
                </button> -->
              </div>
            </div>

            <div class="segmentation">
              <img src="../../../../assets/njImgs/segmentation.png" alt="" />
            </div>

            <div class="bodyRight" v-if="currentLabel === 'distributionBtn'">
              <div class="distribution" style="margin-left: 100px">
                <div class="distributionTitle">作业价格</div>
                <div class="distributionBody">
                  <div class="txtDiv">{{ OrderInfo?.jobPrice }}元/亩</div>
                  <!-- <button class="buttonPrice">修改价格</button> -->
                </div>
              </div>
              <div class="distribution" style="margin-left: 30px">
                <div class="distributionTitle">选择时间</div>
                <div class="distributionBody">
                  <div class="dateBox">
                    <el-date-picker
                      v-model="timeValue1"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                  <div
                    class="dateBox"
                    style="margin-left: 0px; margin-top: -10px"
                  >
                    <el-select
                      value-key="start"
                      v-model="timeValue2"
                      filterable
                      placeholder="请选择"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in option3"
                        :key="item.value.label"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="distribution" style="margin-left: 100px">
                <div class="distributionTitle">选择机手</div>
                <div
                  class="distributionBody"
                  style="margin: 0 auto; position: relative"
                >
                  <button
                    style="margin-bottom: 0px"
                    @click="showDia('njs')"
                    :disabled="timeValue1 && timeValue2 !== '' ? false : true"
                  >
                    选择机手
                  </button>
                  <br />
                  <span
                    style="
                      height: 10px;
                      margin-right: 10px;
                      font-size: 22px;
                      color: #fff;
                      position: absolute;
                      top: 30px;
                      left: 85px;
                    "
                  >
                    {{ njsInfo?.name }}</span
                  >
                </div>
              </div>
              <div class="distribution" style="margin-left: 30px">
                <div class="distributionTitle">选择农机</div>
                <div
                  class="distributionBody"
                  style="margin: 0 auto; position: relative"
                >
                  <button
                    style="margin-bottom: 0px"
                    @click="showDia('nj')"
                    :disabled="timeValue1 && timeValue2 !== '' ? false : true"
                  >
                    选择农机
                  </button>
                  <br />
                  <span
                    style="
                      height: 10px;
                      margin-right: 10px;
                      font-size: 22px;
                      color: #fff;
                      position: absolute;
                      top: 30px;
                      left: 85px;
                    "
                  >
                    {{ njInfo?.carBrand }}</span
                  >
                </div>
              </div>

              <button class="submit_Eidot" @click="fenpei()">确认</button>
            </div>

            <div class="bodyRight" v-else-if="currentLabel === 'EdiotBtn'">
              <div class="distribution" style="margin-left: 100px">
                <div class="distributionTitle">作业价格</div>
                <div class="distributionBody">
                  <div class="txtDiv">{{ OrderInfo?.jobPrice }}元/亩</div>
                  <!-- <button class="buttonPrice">修改价格</button> -->
                </div>
              </div>
              <div class="distribution" style="margin-left: 30px">
                <div class="distributionTitle">选择时间</div>
                <div class="distributionBody">
                  <div class="dateBox">
                    <el-date-picker
                      v-model="timeValue1"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                  <div
                    class="dateBox"
                    style="margin-left: 0px; margin-top: -10px"
                  >
                    <el-select
                      value-key="start"
                      v-model="timeValue2"
                      filterable
                      placeholder="请选择"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in option3"
                        :key="item.value.label"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="distribution" style="margin-left: 100px">
                <div class="distributionTitle" >选择机手</div>
                <div
                  class="distributionBody"
                  style="margin: 0 auto; position: relative"
                >
                  <button
                    style="margin-bottom: 0px"
                    @click="showDia('njs')"
                    :disabled="timeValue1 && timeValue2 !== '' ? false : true"

                  >
                    选择机手
                  </button>
                  <br />
                  <span
                    style="
                      height: 10px;
                      margin-right: 10px;
                      font-size: 22px;
                      color: #fff;
                      position: absolute;
                      top: 30px;
                      left: 85px;
                    "
                  >
                    {{ njsInfo?.name }}</span
                  >
                </div>
              </div>
              <div class="distribution" style="margin-left: 30px">
                <div class="distributionTitle">选择农机</div>
                <div
                  class="distributionBody"
                  style="margin: 0 auto; position: relative"
                >
                  <button style="margin-bottom: 0px" @click="showDia('nj')"
                  
                  :disabled="timeValue1 && timeValue2 !== '' ? false : true"
                  
                  >
                    选择农机
                  </button>
                  <br />
                  <span
                    style="
                      height: 10px;
                      margin-right: 10px;
                      font-size: 22px;
                      color: #fff;
                      position: absolute;
                      top: 30px;
                      left: 85px;
                    "
                  >
                    {{ njInfo?.carBrand }}</span
                  >
                </div>
              </div>

              <button class="submit_Eidot" @click="fenpei()">确认</button>
            </div>

            <div class="bodyRight" v-else>
              <div class="leftInfo">
                <div class="txtclass3" style="margin-top: 40px">
                  <div class="z-img">作业机手</div>
                  <img
                    src="../../../../assets/njImgs/tetBackground.png"
                    alt=""
                  />

                  <div class="textCss">{{ OrderInfo?.jobTractorName }}</div>
                </div>

                <div class="txtclass3" style="margin-top: 50px">
                  <div class="z-img">实际面积</div>
                  <img
                    src="../../../../assets/njImgs/tetBackground.png"
                    alt=""
                  />

                  <div class="textCss">{{ OrderInfo?.jobNum }}亩</div>
                </div>
                <div class="txtclass3" style="margin-top: 40px">
                  <div class="z-img">作业时间</div>
                  <img
                    src="../../../../assets/njImgs/tetBackground.png"
                    alt=""
                  />

                  <div class="textCss">{{ OrderInfo?.orderStartTime }}~</div>
                  <div class="textCss">{{ OrderInfo?.orderFinishTime }}</div>
                </div>
              </div>
              <div>
                <Tmap :mapInfo="OrderInfo" class="rightMap"></Tmap>
              </div>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
    <NjddNjs
      :NjsVisible="NjsVisible"
      @closenjs="closenjs"
      :uid.sync="uid"
      :OrderInfo="OrderInfo"
      :currentLabel="currentLabel"
      :TanleContent="TanleContent"
      @emitInfo="emitInfo"
      @emitInfo2="emitInfo2"
      :total.sync="total"
      :queryObj="queryObj"
    >
    </NjddNjs>
    <njddTmapDia
      :MapDiaVisible.sync="MapDiaVisible"
      @closenjs="closenjs"
      :mapPoint="mapPoint"
    ></njddTmapDia>
  </div>
</template>

<script>
import Tmap from '@/views/njall/components/TMap/map_gj.vue';
import NjddNjs from '@/views/njall/components/njdd/njddnjs.vue';
import { getFreeNj, getFreeNjs, postJobOrder } from '@/api/njApi/njzl.js';
import njddTmapDia from '@/views/njall/components/njdd/njddDiaMap.vue';

export default {
  props: {
    Visible: {
      type: Boolean,
      default: false
    },
    currentLabel: {
      type: String,
      required: true
    },
    OrderInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Tmap,
    NjddNjs,
    njddTmapDia
  },
  data () {
    return {
      mapPoint: [],

      NjsVisible: false,
      uid: 2,
      TanleContent: [],
      queryObj: {
        pageNum: 1,
        pageSize: 10
      },
      total: null,
      njsInfo: {
        name: null
      },
      njInfo: {
        carBrand: null
      },
      DateNow: null,
      DateNow1: null,
      DateNow2: null,
      DateNow3: null,
      timeValue1: '',
      timeValue2: '',
      MapDiaVisible: false,
      option3: [
        {
          value: {
            start: '00:00:00',
            end: '11:59:59'
          },
          label: '上午'
        },
        {
          value: {
            start: '12:00:00',
            end: '23:59:59'
          },
          label: '下午'
        }
      ]
    };
  },
  created () {
    // 获取当前日期
    var currentDate = new Date();

    var currentFormattedDate = `${
      currentDate.getMonth() + 1
    }/${ currentDate.getDate() }`;

    // 获取接下来四天的日期
    var nextFourDays = [];
    for (var i = 1; i <= 4; i++) {
      var nextDate = new Date();
      nextDate.setDate(currentDate.getDate() + i);
      var nextFormattedDate = `${ nextDate.getMonth() + 1 }/${ nextDate.getDate() }`;
      nextFourDays.push(nextFormattedDate);
    }

    this.DateNow = currentFormattedDate;
    this.DateNow1 = nextFourDays[0];
    this.DateNow2 = nextFourDays[1];
    this.DateNow3 = nextFourDays[2];
  },
  mounted () {
    // this.njsInfo.name = this.OrderInfo.farmerName;
    // this.njInfo.name = this.OrderInfo.carBrand;
  },

  methods: {
    open () {
      this.njsInfo.name = this.OrderInfo.jobTractorName;
      this.njInfo.carBrand = this.OrderInfo.carBrand;
    },
    closeMask () {
      this.$emit('update:Visible', false);
      this.njInfo = { carBrand: null };
      this.njsInfo = { name: null };
      this.timeValue1 = '';
      this.timeValue2 = '';
    },

    closenjs (item) {
      this.NjsVisible = item;

      this.uid = null;
    },

    async getNj () {
      if (this.OrderInfo.jobType === '耕整作业') {
        const { data } = await getFreeNj({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: '耕整作业',
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`,
          operatorId: this.njsInfo.id
        });
        this.total = data.total;
        this.TanleContent = data.records;
      } else if (this.OrderInfo.jobType === '机插作业') {
        const { data } = await getFreeNj({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: '机插作业',
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`,
          operatorId: this.njsInfo.id
        });
        this.total = data.total;

        this.TanleContent = data.records;
      } else if (this.OrderInfo.jobType === '机收作业') {
        const { data } = await getFreeNj({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: this.OrderInfo.jobObj,
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`,
          operatorId: this.njsInfo.id
        });
        this.total = data.total;
        this.TanleContent = data.records;
      } else {
        const { data } = await getFreeNj({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: this.OrderInfo.jobObj,
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`,
          operatorId: this.njsInfo.id
        });
        this.total = data.total;
        this.TanleContent = data.records;
      }
    },
    async getNjs () {
      if (this.OrderInfo.jobType === '耕整作业') {
        const { data } = await getFreeNjs({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: '耕整作业',
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`
        });
        this.total = data.total;

        this.TanleContent = data.records;
      } else if (this.OrderInfo.jobType === '机插作业') {
        const { data } = await getFreeNjs({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: '机插作业',
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`
        });
        this.total = data.total;

        this.TanleContent = data.records;
      } else if (this.OrderInfo.jobType === '机收作业') {
        const { data } = await getFreeNjs({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: this.OrderInfo.jobObj,
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`
        });
        this.total = data.total;

        this.TanleContent = data.records;
      } else {
        const { data } = await getFreeNjs({
          pageNum: this.queryObj.pageNum,
          pageSize: this.queryObj.pageSize,
          objType: this.OrderInfo.jobType,
          jobObj: this.OrderInfo.jobObj,
          startTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
          endTime: `${ this.timeValue1 } ${ this.timeValue2.end }`
        });
        this.total = data.total;

        this.TanleContent = data.records;
      }
    },
    async fenpei () {
      let res = {
        id: this.OrderInfo.id,
        jobPrice: this.OrderInfo.jobPrice,
        jobPriceAmount: +this.putjobPriceAmount,
        jobTractorName: this.njsInfo.name,
        jobTractorId: this.njsInfo.id,
        jobTractorTel: this.njsInfo.telphone,
        jobMachineryId: this.njInfo.id,
        jobStartTime: `${ this.timeValue1 } ${ this.timeValue2.start }`,
        jobFinishTime: `${ this.timeValue1 } ${ this.timeValue2.end }`,
        isSystem: true
      };

      const data = await postJobOrder({ ...res });
      this.$message(data.msg);
      this.$emit('parent');
      this.closeMask();
    },

    openDiaMap () {
      const axios = require('axios');

      // 地址信息
      const address = this.OrderInfo?.jobLocation;

      // 构建请求URL
      const baseUrl = 'https://api.tianditu.gov.cn/geocoder';
      const apiKey = 'c9cd7951db1ea5b311aae41f5c0d60e4';
      const requestUrl = `${ baseUrl }?ds={"keyWord":"${ encodeURIComponent(
        address
      ) }"}&tk=${ apiKey }`;

      // 发送GET请求
      axios
        .get(requestUrl)
        .then(response => {
          // 解析响应数据
          const result = response.data;
          const location = result.location;

          // 提取经纬度信息
          const longitude = location.lon;
          const latitude = location.lat;

          // 打印经纬度信息

          const coordinates = [ Number(longitude), Number(latitude) ];
          this.mapPoint = coordinates;
        })
        .catch(error => {
          console.error('地理编码请求出错:', error);
        });
      setTimeout(() => {
        this.MapDiaVisible = true;
      }, 500);
    },

    parent () {
      this.$emit('parent', '耕整作业');
    },
    showDia (typeofs) {
      if (typeofs === 'njs') {
        this.uid = 2;
        this.getNjs();
      } else {
        this.uid = 3;

        this.getNj();
      }
      this.NjsVisible = true;
    },
    emitInfo (data) {
      this.njsInfo = data;
    },
    emitInfo2 (data) {
      this.njInfo = data;
    },

    handleCurrentChange (item) {
      this.queryObj.pageNum = item;

      this.getNj();
    },
    handleSizeChange (item) {
      this.queryObj.pageSize = item;
    }
  },
  computed: {
    putjobPriceAmount () {
      return `${ this.OrderInfo.jobNum * this.OrderInfo.jobPrice }`;
    }
  }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(16vw);
  box-sizing: border-box;
}

::v-deep .el-dialog {
  width: companyW(1348vw);
  height: companyH(660vh);
  background: #000000;
  background: rgba($color: #000000, $alpha: 0.9);
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}

::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .cell {
  color: #fff;
  font-size: companyW(18vw);
}
::v-deep .el-input--prefix .el-input__inner {
  background-color: #0079a1;
  border: #0081c3;
}
::v-deep .el-input--suffix .el-input__inner {
  background-color: #0079a1;
  border: #0081c3;
}

::v-deep [data-v-658199c4] .el-input--suffix .el-input__inner {
  color: #fff;
}
.toolbox {
  height: companyH(660vh);

  padding-top: companyH(30vh);
  margin-bottom: companyH(50vh);
  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
  .titleBox {
    text-align: center;
    margin-left: companyW(120vw);
    display: flex;

    .leftTitle {
      background-color: transparent;
      width: companyW(450vw);
      color: #5598bb;
      margin-left: companyW(-85vw);
    }
    .rightTitle {
      background-color: transparent;
      width: companyW(700vw);

      color: #5598bb;
      padding-left: companyW(-700vw);
    }
  }
  .bodyBox {
    margin-top: companyH(50vh);
    margin-left: companyW(120vw);
    height: 100%;
    display: flex;
    .bodyLeft {
      display: flex;
      flex-wrap: wrap;
      width: companyW(410vw);
      height: companyH(420vh);

      .txtclass2 {
        width: companyW(400vw);
        height: companyH(60vh);
        position: relative;

        .z-img {
          font-size: companyW(20vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #eff0f1;
        }
        img {
          position: absolute;
          top: companyH(10vh);
          left: companyW(-5vw);
          // z-index: -1;
        }
        .textCss {
          margin-top: companyH(17vh);
          font-size: companyW(18vw);
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .txtClass {
        width: companyW(200vw);
        height: companyH(60vh);
        position: relative;
        .z-img {
          font-size: companyW(16vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #eff0f1;
        }
        img {
          position: absolute;
          top: companyH(10vh);
          left: companyW(-5vw);
          // z-index: -1;
        }
        .textCss {
          margin-top: companyH(17vh);
          font-size: companyW(18vw);
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .Eidot {
        margin-left: companyW(40vw);
        margin-top: companyH(20vw);
        .submit_Eidot {
          width: companyW(201vw);
          height: companyH(41vh);
          background: rgba(55, 57, 66, 0.54);
          border: 1px solid #00a8ff;
          font-size: companyW(18vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #00a8ff;
          line-height: companyW(41vw);
        }
      }
    }
    .bodyRight {
      display: flex;

      flex-wrap: wrap;
      width: companyW(700vw);
      height: 80%;
      .distribution {
        width: companyW(240vw);
        height: companyH(160vh);
        background: rgba(0, 121, 161, 0.6);
        border: 1px solid #00b9f7;

        .distributionTitle {
          text-align: center;
          width: companyW(238vw);
          height: companyH(40vh);
          font-size: companyW(18vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: companyH(40vh);
          background: #013656;
        }
        .distributionBody {
          text-align: center;
          line-height: companyW(160vh);
          button {
            width: companyW(81vw);
            height: companyH(25vh);
            background: rgba(55, 57, 66, 0.54);
            border: 1px solid #00a8ff;
            font-size: companyW(12vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #00a8ff;
            line-height: companyH(25vh);
          }
          .txtDiv {
            font-size: companyW(16vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          .buttonPrice {
            width: companyW(81vw);
            height: companyH(25vh);
            background: rgba(55, 57, 66, 0.54);
            border: 1px solid #00a8ff;
            font-size: companyW(12vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #00a8ff;
            transform: translateY(-200%);
          }
          .dateBox {
            line-height: companyH(70vh);
            margin-left: companyW(0vw);
            .el-input__inner {
              border: #0081c3 1px solid;
            }
          }
        }
      }
      .leftInfo {
        width: companyW(300vw);
        height: companyH(400vh);
        .txtclass3 {
          width: companyW(400vw);
          height: companyH(60vh);
          position: relative;

          .z-img {
            font-size: companyW(16vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #eff0f1;
          }
          img {
            position: absolute;
            top: companyH(10vh);
            left: companyW(-5vw);
            // z-index: -1;
          }
          .textCss {
            margin-top: companyH(17vh);
            font-size: companyW(18vw);
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .rightMap {
        transform: translateX(5%);
        width: companyW(400vw);
        height: companyH(400vh);
      }
      .submit_Eidot {
        margin-left: companyW(240vw);
        width: companyW(201vw);
        height: companyH(41vh);
        background: rgba(55, 57, 66, 0.54);
        border: 1px solid #00a8ff;
        font-size: companyW(18vw);
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00a8ff;
        line-height: companyW(41vw);
      }
    }
  }
}
</style>
