<template>
  <div>
    <div class="njRightTitle">
      <div class="leftContent">
        <div class="midPositionP">
          <el-select
            v-model="currentT"
            :popper-append-to-body="false"
            @change="getfin(currentT)"
          >
            <el-option
              v-for="item in TimeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="rigBtn" @click="openAddDialog()" v-if="uid === 2">
        新增委托
      </div>
    </div>
    <div class="labelBox" v-if="uid === 2">
      <div
        :class="DateNow5 === 0 ? 'dayButton currentNav' : 'dayButton'"
        @click="getOrderInfoButton(0)"
      >
        {{ DateNow }}({{ ButtonInfo[0]?.count }})
      </div>
      <div
        :class="DateNow5 === 1 ? 'dayButton currentNav' : 'dayButton'"
        @click="getOrderInfoButton(1)"
      >
        {{ DateNow1 }}({{ ButtonInfo[1]?.count }})
      </div>
      <div
        :class="DateNow5 === 2 ? 'dayButton currentNav' : 'dayButton'"
        @click="getOrderInfoButton(2)"
      >
        {{ DateNow2 }}({{ ButtonInfo[2]?.count }})
      </div>
      <div
        :class="DateNow5 === 3 ? 'dayButton currentNav' : 'dayButton'"
        @click="getOrderInfoButton(3)"
      >
        {{ DateNow3 }}({{ ButtonInfo[3]?.count }})
      </div>
      <div
        :class="DateNow5 === 4 ? 'dayButton currentNav' : 'dayButton'"
        @click="getOrderInfoButton(4)"
      >
        {{ DateNow4 }}({{ ButtonInfo[4]?.count }})
      </div>
    </div>
    <div>
      <div class="rightTable">
        <el-table :data="propData" :show-header="false">
          <template v-for="v in propTitle">
            <el-table-column
              v-if="v.logo"
              :key="v.id"
              :prop="v.prop"
              :label="v.label"
              :min-width="v.width"
            >
              <template slot-scope="scope">
                <div class="ListIcon">{{ scope.row[v.prop] }}</div>
              </template>
            </el-table-column>

            <el-table-column
              v-else-if="v.text"
              :key="v.id"
              :prop="v.prop"
              :label="v.label"
              :min-width="v.width"
            >
            </el-table-column>
            <el-table-column
              v-else-if="v.btn"
              :key="v.id"
              :prop="v.prop"
              :label="v.label"
              :min-width="v.width"
            >
              <template slot-scope="scope">
                <div
                  :class="v.btnColor == 'yellow' ? 'blueBtn' : 'yellowBtn'"
                  @click="openSTR(v.label, scope.row)"
                >
                  {{ scope.row[v.prop] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="v.id"
              align="center"
              :prop="v.prop"
              :label="v.label"
              :sortable="v.isSort"
              :min-width="v.width"
              @cell-mouse-enter="ent"
            ></el-table-column>
          </template>
        </el-table>
      </div>
    </div>
    <addContracts
:addVisible="addVisible"
@closeMask="closeMask"
@getData="getData"
@parent="parent"
:options1="options1">
    </addContracts>
    <Stribution
      :Visible.sync="Visible"
      @closeMask="closeMask2"
      :currentLabel="currentLabel"
      :OrderInfo.sync="OrderInfo"
      @parent="parent"

    >
    </Stribution>
  </div>
</template>

<script>
import addContracts from '@/views/njall/components/njdd/njddAddContracts.vue';
import Stribution from '@/views/njall/components/njdd/njdddiStribution.vue';
import { getOrderIn } from '@/api/njApi/njzl.js';
import { getJobTypePrice } from '@/api/njApi/njdd.js';

export default {
  props: {
    TimeOptions: {
      type: Array,
      default: () => []
    },
    propTitle: {
      type: Array,
      default: () => []
    },
    propData: {
      type: Array,
      default: () => []
    },
    uid: {
      type: Number
    },
    ButtonInfo:{
      type:Array,
      default: () => []

    }
  },
  components: {
    addContracts,
    Stribution
  },
  data () {
    return {
      currentLabel: '',
      currentT: '',
      ddSearch: '',
      addVisible: false,
      Visible: false,
      OrderInfo: null,
      DateNow: null,
      DateNow1: null,
      DateNow2: null,
      DateNow3: null,
      DateNow4: null,
      DateNow5: null,
      tabLoading: false,
      pageNum: 1,
      pageSize: 10,
      options1:[]
    };
  },
  methods: {
    openAddDialog () {
      this.getJobType();
      this.addVisible = true;
    },
    closeMask (item) {
      this.addVisible = item;
    },
    closeMask2 (item) {
      this.Visible = item;
    },
    async  getJobType(){
      const { data }= await getJobTypePrice();
      this.options1=data.map(item => {
        return {
          label:item.jobType,
          value:{
            value:item.jobPrice,
            label:item.jobType
          }
        };
      });

    },
    async openSTR (label, v) {
      if (label === 'distributionBtn') {
        this.currentLabel = label;
        const { data } = await getOrderIn(v.id);
        this.OrderInfo = data;

        this.Visible = true;
        // const data= await getWait();
      } else if (label === 'EdiotBtn') {
        const { data } = await getOrderIn(v.id);

        this.OrderInfo = data;
        this.currentLabel = label;
        this.Visible = true;
      } else {
        const { data } = await getOrderIn(v.id);

        this.OrderInfo = data;
        this.currentLabel = label;
        this.Visible = true;
      }
    },
    parent () {
      this.$emit('gettodaydd', '');
      this.$emit('getButtonInfo');


    },
    getfin (row) {
      if (row === 'gzzy') {
        this.$emit('gettodaydd', '耕整作业');
      } else if (row === 'jxzy') {
        this.$emit('gettodaydd', '机插作业');
      } else if (row === 'jszy') {
        this.$emit('gettodaydd', '机收作业');
      } else if(row==='zbzy') {
        this.$emit('gettodaydd', '植保作业');
      }else{
        this.$emit('gettodaydd', '');

      }
    },
    getOrderInfoButton (e) {
      this.DateNow5 = e;
      this.$emit('DateNow111', e);

      if (e === 0) {
        this.$emit('OrderInfoButton', 0);
      } else if (e === 1) {
        this.$emit('OrderInfoButton', 1);
      } else if (e === 2) {
        this.$emit('OrderInfoButton', 2);
      } else if (e === 3) {
        this.$emit('OrderInfoButton', 3);
      } else {
        this.$emit('OrderInfoButton', 4);
      }
    },
    getData(){
      this.$emit('getData');

    }
  },

  created () {
    // 获取当前日期
    var currentDate = new Date();

    // 格式化当前日期为"8/27"的格式
    var currentFormattedDate = `${
      currentDate.getMonth() + 1
    }/${ currentDate.getDate() }`;

    // 获取接下来四天的日期
    var nextFourDays = [];
    for (var i = 1; i <= 4; i++) {
      var nextDate = new Date();
      nextDate.setDate(currentDate.getDate() + i);
      var nextFormattedDate = `${ nextDate.getMonth() + 1 }/${ nextDate.getDate() }`;
      nextFourDays.push(nextFormattedDate);
    }

    this.DateNow = currentFormattedDate;
    this.DateNow1 = nextFourDays[0];
    this.DateNow2 = nextFourDays[1];
    this.DateNow3 = nextFourDays[2];
    this.DateNow4 = nextFourDays[3];
  },
  mounted () {
    this.currentT = this.TimeOptions[0].label;
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
::v-deep .el-icon-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inpt {
  width: companyW(200vw);
}
.leftContent {
  display: flex;
  align-items: center;
}
.ListOne {
  display: flex;
  align-items: center;
  padding: companyW(10vw);
}
.ListIcon {
  width: companyW(20vw);
  background: #00a8ff;
  color: #fff;
  // opacity: 0.5;
  font-size: companyH(14vh);
  text-align: center;
  line-height: companyH(22vh);
  border-radius: 3px;
}
.toolStyle {
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff9f893;
}
.labelText {
  color: #fff;
}
.yellowBtn,
.blueBtn {
  width: companyW(66vw);
  line-height: companyH(21vh);
  background: rgba(55, 57, 66, 0.54);
  border: companyH(1vh) solid #e4a746;
  color: #e4a746;
  text-align: center;
}
::v-deep .rightTable .el-table .el-table__row {
  height: companyH(40vh);
  line-height: companyH(40vh);
}
.blueBtn {
  border: companyH(1vh) solid #00a8ff;
  color: #00a8ff;
}
::v-deep .rightTable {
  .el-table {
    background: #00000000;
    .el-table__row {
      background: #00000000;
      .el-table__cell {
        padding: 0;
        color: #fff;
        border-bottom: 1px solid #696969;
        font-size: companyW(15vw);
      }
    }
  }
}
::v-deep [data-v-b67e9fc4] .midPositionP .el-select .el-input__inner {
  font-size: companyW(18vw);
  width:companyW(120vw);
  height: companyH(35vh);


}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #999 !important;
}
.el-table::before {
  all: initial;
}
.labelBox {
  display: flex;
  justify-content: space-around;
  width: 100%;
  .dayButton {
    text-align: center;
    width: companyW(70vw);
    height: companyH(30vh);
    border: 1px solid #9da1a1;
    font-size: companyW(16vw);
    font-family: Source Han Sans CN;
    font-weight: 700;
    color: #fff;
    line-height: companyW(30vw);
    background: #082e35;
  }
  .dayButton:hover {
    opacity: 0.7 !important;
    transition: all 0.5s;
  }
  .currentNav {
    text-align: center;
    width: companyW(70vw);
    height: companyH(30vh);
    border: 1px solid #009bed;
    font-size: companyW(16vw);
    font-family: Source Han Sans CN;
    font-weight: 700;
    color: #009bed;
    line-height: companyW(30vw);
    background: #082e35;
  }
}
.rigBtn {
  width: companyW(99vw);
  height: companyH(29vh);
  border: companyW(1vw) solid;
  border-radius: 6px;
  text-align: center;
  line-height: 29px;
  font-size: companyH(12vh);
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.njRightTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep .midPositionP {
  .el-select {
    width: companyW(110vw);
    margin: companyH(14vh) companyW(20vw);
    .el-input__inner {
      height: companyH(28vh);
      background: rgba(6, 40, 62, 0.9);
      border: 1px solid #00def0;
      box-shadow: 0px 0px 8px 0px rgba(77, 247, 233, 0.1);
      color: #fff;
    }
  }
}
::v-deep .inputRig {
  .el-input__inner {
    width: companyW(180vw);
    height: companyH(29vh);
    background: rgba(6, 40, 62, 0.9);
    border: 1px solid #00def0;
    box-shadow: 0px 0px 8px 0px rgba(77, 247, 233, 0.1);
  }
}
</style>
